export enum INSTRUCTIONS_TYPES {
  ID = 'ID',
  KYC = 'KYC',
  ADDRESS = 'ADDRESS',
}

export enum LANGUAGES {
  AR = 'ar',
  EN = 'en',
}

export enum STORAGE_ITEMS {
  AUTH_DATA = 'AUTH_DATA',
  REFRESH_TOKEN_EXPIRE_TIME = 'REFRESH_TOKEN_EXPIRE_TIME',
  REFRESH_TOKEN_TIME_LEFT = 'REFRESH_TOKEN_TIME_LEFT',
  AUTH_DATA_TEMP = 'AUTH_DATA_TEMP',
  PARTICIPANT_KEY = 'PARTICIPANT_KEY',
  DEVICE_ID = 'DEVICE_ID',
  APP_SESSION_TIMEOUT = 'APP_SESSION_TIMEOUT',
  LAST_APP_ACTIVE_TIME = 'LAST_APP_ACTIVE_TIME',
}

export enum CurrentStageEnum {
  Welcome = 'Welcome',
  Instruction = 'Instruction',
  CardScan = 'CardScan',
  Selfie = 'Selfie',
  Results = 'Results',
  Consent = 'Consent',
  Finish = 'Finish',
}

export enum STATE_STATUSES {
  UPDATED,
  NEED_TO_UPDATE,
}

export enum ConsentActions {
  Approve,
  Reject,
  Revoke,
  SeeDetails,
}

export enum CONSENT_STATUS_FILTER {
  all = 'all',
  pending = 'pending',
  allowed = 'allowed',
  accepted = 'accepted',
  revoked = 'revoked',
  rejected = 'rejected',
  expired = 'expired',
}

export enum EDocType {
  JORDAN = 'JORDAN',
  INTERNATIONAL = 'INTERNATIONAL',
}
