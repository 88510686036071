import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { toast } from 'react-toastify';

import { jopaccApi } from '../../../../shared/api';
import DataTable from '../../../components/DataTable';

import { getDataTableOptions } from './datatable-options';

const onTableAction = (action, data, router) => {
  if (action === 'billing') {
    router.push('/jopacc/billing/participant', data);
  }
};

const JopaccBilling = props => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataTableOptions, setDataTableOptions] = useState(
    getDataTableOptions(0, 10, (action, data) => onTableAction(action, data, props.history), props.t)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await jopaccApi.getJopaccBillingParticipantList();
        setData(response.data.data);
        setDataTableOptions(
          getDataTableOptions(
            response.data.data.length,
            10,
            (action, data) => onTableAction(action, data, props.history),
            props.t
          )
        );
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || props.t('error'));
      }
      setLoading(false);
    };

    fetchData();
  }, [props.history]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('Participants Billing')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="d-flex mx-3 mb-3">
              <h4 className="font-size-24">{props.t('Participants Billing')}</h4>
            </div>

            <DataTable data={data} options={dataTableOptions} />
          </>
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(JopaccBilling);
