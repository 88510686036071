import { jopaccApi } from '../../api';
import { IAddParticipantUserRequest, IAddParticipantUserResponse, IParticipantUser } from '../../types/api';

export const addJopaccUser = async (user: IAddParticipantUserRequest): Promise<IAddParticipantUserResponse> => {
  // TODO: Keep extra fields and add then to UI or remove and make optional in the api
  user = {
    ...user,
    country: 'Jordan',
  };

  const { data } = await jopaccApi.addUser(user);
  return data;
};

export const updateJopaccUser = async (userId: number, user: IAddParticipantUserRequest): Promise<any> => {
  return await jopaccApi.updateUser(userId, user);
};

export const deleteJopaccUser = async (id: number): Promise<any> => {
  return await jopaccApi.deleteUser(id);
};

export const getJopaccUsers = async (): Promise<IParticipantUser[]> => {
  const {
    data: { data },
  } = await jopaccApi.getJopaccUsers();
  return data;
};
