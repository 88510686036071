import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from 'reactstrap';

const StatisticsCards = ({ data }) => {
  const { t } = useTranslation();
  const { pending, allowed, rejected, revoked, expired } = data;

  const reports = [
    { title: t('Pending consents'), iconClass: 'bx-copy-alt', description: pending },
    { title: t('Approved consents'), iconClass: 'bx-archive-in', description: allowed },
    {
      title: t('Rejected consents'),
      iconClass: 'bx-purchase-tag-alt',
      description: rejected,
    },
    {
      title: t('Revoked consents'),
      iconClass: 'bx-purchase-tag-alt',
      description: revoked,
    },
    {
      title: t('Expired consents'),
      iconClass: 'bx-purchase-tag-alt',
      description: expired,
    },
  ];

  return (
    <Row>
      {reports.map((report, key) => (
        <Col md="12" xxl key={'_col_' + key}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">{report.title}</p>
                  <h4 className="mb-0">{report.description}</h4>
                </div>
                {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={'bx ' + report.iconClass + ' font-size-24'} />
                  </span>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default StatisticsCards;
