import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { api } from '../../api';
import { STATE_STATUSES } from '../../constants/enums';
import { IAppState, setConsents, setConsentStatus } from '../../store/reducers/app.reducer';
import { IRootState } from '../../store';

export const useGetConsents = onError => {
  const dispatch = useDispatch();
  const { consentStatus, profile } = useSelector<IRootState>(state => state.app) as IAppState;

  const getConsents = useCallback(async () => {
    try {
      // MOBILE AND USER PORTAL (non-paginated with client side search...)

      const { data } = await api.getConsents(profile.data.id);
      dispatch(setConsents(data.data));
      dispatch(setConsentStatus(STATE_STATUSES.UPDATED));
    } catch (error) {
      onError(error);
    }
  }, [dispatch, onError, profile.data.id]);

  useEffect(() => {
    getConsents();
  }, [getConsents]);

  useEffect(() => {
    if (consentStatus === STATE_STATUSES.NEED_TO_UPDATE) {
      getConsents();
    }
  }, [getConsents, consentStatus]);
};
