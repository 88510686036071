import { configureStore } from '@reduxjs/toolkit';

import appReducer from './reducers/app.reducer';

const middlewares = [
  /* other middlewares */
];

export const store = configureStore({
  reducer: {
    app: appReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares),
});
export type IRootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;
