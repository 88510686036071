import React from 'react';
import { Modal } from 'reactstrap';

const UiModal = ({ isOpen, toggle, body, footer = undefined, title = undefined }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className={`modal-header ${!title && 'border-bottom-0'}`}>
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{body}</div>
      {!!footer && <div className="modal-footer">{footer}</div>}
    </Modal>
  );
};

export default UiModal;
