import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import qs from 'qs';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

import ServerDataTable from '../../../components/ServerDataTable';
import { CONSENT_STATUS_FILTER } from '../../../../shared/constants/enums';
import { COLORS } from '../../../../shared/constants/colors';
import { getParticipantConsents } from '../../../../shared/services/consents';

import getTableOptions from './constants';
import SearchBar from './SearchBar';

const Consents = ({ t, history }) => {
  const [loading, setLoading] = useState(true);
  const [consents, setConsents] = useState([]);
  const [dataTableOptions, setDataTableOptions] = useState(
    getTableOptions(CONSENT_STATUS_FILTER.accepted, t)(0, id => history.push('/customers/details', { userId: id }))
  );
  const [consentCounts, setConsentCounts] = useState({
    all: '0',
    pending: '0',
    allowed: '0',
    revoked: '0',
    rejected: '0',
    expired: '0',
  });
  const [queryOptions, setQueryOptions] = useState({
    page: 1,
    limit: 10,
    status: CONSENT_STATUS_FILTER.accepted,
  });

  const fetchConsents = useCallback(async () => {
    setLoading(true);

    try {
      const queryObj = Object.keys(queryOptions).reduce((p, c) => {
        if (queryOptions[c]) {
          p = { ...p, [c]: queryOptions[c] };
        }
        return p;
      }, {});

      const response = await getParticipantConsents(qs.stringify(queryObj));
      const normalizedConsents = [];

      for (const user of response.data.users) {
        for (const consent of user.consents) {
          normalizedConsents.push({
            ...user,
            ...consent,
            // TODO: decide on how to display the ID => currently very long to display fully
            // TODO2: decide whether to show user or consent id
            // id: consent.id.substring(0, 10).concat('...'),
            id: user.id,
          });
        }
      }

      setConsents(normalizedConsents);
      setConsentCounts(response.data.consentsCounts);
      setDataTableOptions(
        getTableOptions(queryOptions.status, t)(response.options, id =>
          history.push('/customers/details', { userId: id })
        )
      );
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || t('error'));
    }

    setLoading(false);
  }, [queryOptions, history]);

  useEffect(() => {
    fetchConsents();
  }, [fetchConsents, queryOptions]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage: limit } = newState;

    setQueryOptions({
      ...queryOptions,
      page,
      limit,
    });
  };

  const onFilterApplied = filter => {
    setQueryOptions({
      ...queryOptions,
      ...filter,
    });
  };

  const onFilterClicked = status => {
    if (status !== queryOptions.status) {
      setQueryOptions({ ...queryOptions, status: status });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('Customers')} | Jopacc</title>
        </MetaTags>
        <Container fluid>
          <div>
            <div className="ms-3">
              <h4 className="font-size-24 mb-2">
                {t('Customers')} {loading && <i className="bx bx-loader bx-spin align-middle me-2" />}
              </h4>
            </div>
            <div className="d-flex">
              <button
                className={'btn btn-sm px-3 font-size-14 header-item'}
                style={queryOptions.status === CONSENT_STATUS_FILTER.accepted ? { color: COLORS.yellow } : {}}
                type="button"
                onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.accepted)}>
                {`${t('Approved')} ${consentCounts.allowed}`}
              </button>
              <button
                className={'btn btn-sm px-3 font-size-14 header-item'}
                style={queryOptions.status === CONSENT_STATUS_FILTER.pending ? { color: COLORS.yellow } : {}}
                type="button"
                onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.pending)}>
                {`${t('Pending')} ${consentCounts.pending}`}
              </button>
              <button
                className={'btn btn-sm px-3 font-size-14 header-item'}
                style={queryOptions.status === CONSENT_STATUS_FILTER.rejected ? { color: COLORS.yellow } : {}}
                type="button"
                onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.rejected)}>
                {`${t('Rejected')} ${consentCounts.rejected}`}
              </button>
              <button
                className={'btn btn-sm px-3 font-size-14 header-item'}
                style={queryOptions.status === CONSENT_STATUS_FILTER.revoked ? { color: COLORS.yellow } : {}}
                type="button"
                onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.revoked)}>
                {`${t('Revoked')} ${consentCounts.revoked}`}
              </button>
              <button
                className={'btn btn-sm px-3 font-size-14 header-item'}
                style={queryOptions.status === CONSENT_STATUS_FILTER.expired ? { color: COLORS.yellow } : {}}
                type="button"
                onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.expired)}>
                {`${t('Expired')} ${consentCounts.expired}`}
              </button>
            </div>
            <ServerDataTable
              onTableChange={onTableChange}
              data={consents}
              options={dataTableOptions}
              withoutSearch
              headerComponent={
                <SearchBar loading={loading} onApply={onFilterApplied} statusFilter={queryOptions.status} />
              }
              keyField="uid"
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

Consents.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Consents));
