import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import qs from 'qs';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

import ServerDataTable from '../../../components/ServerDataTable';
import { getCBJConsents } from '../../../../shared/services/consents';

import getTableOptions from './constants';
import SearchBar from './SearchBar';

const CBJConsents = ({ t, history }) => {
  const [loading, setLoading] = useState(true);
  const [consents, setConsents] = useState([]);
  const [dataTableOptions, setDataTableOptions] = useState(
    getTableOptions(0, id => history.push('/customers/details', { userId: id }), t)
  );
  const [queryOptions, setQueryOptions] = useState({
    page: 1,
    limit: 10,
  });

  const fetchConsents = useCallback(async () => {
    setLoading(true);

    try {
      const queryObj = Object.keys(queryOptions).reduce((p, c) => {
        if (queryOptions[c]) {
          p = { ...p, [c]: queryOptions[c] };
        }
        return p;
      }, {});

      const response = await getCBJConsents(qs.stringify(queryObj));
      setConsents(response.data);
      setDataTableOptions(
        getTableOptions(response.options, id => history.push('/customers/details', { userId: id }), t)
      );
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || t('error'));
    }

    setLoading(false);
  }, [history, queryOptions]);

  useEffect(() => {
    fetchConsents();
  }, [fetchConsents, queryOptions]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage: limit } = newState;

    setQueryOptions({
      ...queryOptions,
      page,
      limit,
    });
  };

  const onFilterApplied = filter => {
    setQueryOptions({
      ...queryOptions,
      ...filter,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('Customers')} | Jopacc</title>
        </MetaTags>
        <Container fluid>
          <div>
            <div className="ms-3">
              <h4 className="font-size-24 mb-2">
                {t('Customers')} {loading && <i className="bx bx-loader bx-spin align-middle me-2" />}
              </h4>
            </div>
            <ServerDataTable
              onTableChange={onTableChange}
              data={consents}
              options={dataTableOptions}
              withoutSearch
              headerComponent={<SearchBar loading={loading} onApply={onFilterApplied} />}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

CBJConsents.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(CBJConsents));
