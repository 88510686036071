import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, FormFeedback, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import UiModal from '../../../components/Modal';
import { verifyEmailAndSendOTP } from '../../../../shared/services/user';
import extractErrorMessage from '../../../helpers/extractErrorMessage';

const ChangeEmailModal = ({ isOpen, onClose, currentEmail, onSave, t }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const initialValues = {
    newEmail: '',
  };

  const validationSchema = Yup.object().shape({
    newEmail: Yup.string().email(t('Must be a valid Email')).max(255).required(t('Please, fill the required field')),
  });

  const onSubmit = async values => {
    if (currentEmail.toLowerCase() === values.newEmail.toLowerCase()) {
      setError(t('Please, add a new e-mail address'));
      return toast.warning(t('Please, add a new e-mail address'));
    }

    setLoading(true);
    try {
      await verifyEmailAndSendOTP({ email: values.newEmail });
      onSave(values.newEmail);
    } catch (error) {
      let message = extractErrorMessage(error) ?? t('error');
      setError(message);
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <UiModal
      isOpen={isOpen}
      toggle={onClose}
      body={
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {formik => (
            <Form className="needs-formik" onSubmit={formik.handleSubmit}>
              <div className="text-center mb-3">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light  rounded-circle text-primary h1">
                    <i className="mdi mdi-email-open" />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4 className="text-primary">{t('Change Email Address')}</h4>
                    <p className="text-muted font-size-14 mb-4">
                      {t('An OTP will be sent to your new email address.')}
                    </p>
                    <div className="input-group rounded bg-light">
                      <Input
                        placeholder={t('Enter Email address')}
                        // type="email"
                        className="form-control bg-transparent border-0 form-control"
                        name="newEmail"
                        onChange={e => {
                          setError('');
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.newEmail || ''}
                        invalid={!!(formik.touched.newEmail && formik.errors.newEmail)}
                      />
                      <button disabled={loading} type="submit" id="button-addon2" className="btn btn-primary">
                        {loading ? (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                        ) : (
                          <i className="bx bxs-paper-plane" />
                        )}
                      </button>
                    </div>
                    <div className="invalid-label-container">
                      {((formik.touched.newEmail && formik.errors.newEmail) || error) && (
                        <label className="text-danger">{formik.errors.newEmail || error}</label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default withTranslation()(ChangeEmailModal);
