import React from 'react';
import { capitalize } from 'lodash';
import moment from 'moment';

const tableOptions = (pagination, onAction, t) => ({
  columns: [
    {
      dataField: 'id',
      text: t('ID'),
    },
    {
      dataField: 'Name',
      text: t('Name'),
      formatter: (cell, row) => `${capitalize(row.firstName)} ${capitalize(row.lastName)}`,
    },
    {
      dataField: 'consentsCount',
      text: t('Consents Count'),
    },
    {
      dataField: 'idNumber',
      text: t('National ID'),
    },
    {
      dataField: 'createAt',
      text: t('Date of Onboarding'),
      formatter: (cell, row) => {
        return cell && moment(cell).format('DD.MM.YYYY, hh:mm A');
      },
    },
    {
      dataField: 'lastLogin',
      text: t('Last Login'),
      formatter: (cell, row) => {
        return cell && moment(cell).format('DD.MM.YYYY, hh:mm A');
      },
    },
    {
      dataField: 'id',
      text: t('Actions'),
      formatter: (cell, row) => {
        return (
          <label className="table-action" onClick={() => onAction(cell)}>
            <i className="fa fa-eye" />
            {t('Details')}
          </label>
        );
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pagination?.paginationParams?.limit || 10,
    totalSize: pagination?.totalCount || 0,
    custom: false,
  },
});

export default tableOptions;
