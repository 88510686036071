import React from 'react';
import moment from 'moment';
import { capitalize } from 'lodash';

export const getDataTableOptions = (options, pageSize, onAction, t) => ({
  columns: [
    {
      dataField: 'participant',
      text: t('Participant'),
      sort: false,
    },
    {
      dataField: 'dateOfIssue',
      text: t('Date Of Issue'),
      sort: false,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '';
      },
    },
    {
      dataField: 'status',
      text: t('Status'),
      sort: false,
      formatter: cell => {
        return <label>{t(capitalize(cell))}</label>;
      },
    },
    {
      dataField: 'dateOfOperation',
      text: t('Date Of Operation'),
      sort: false,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '';
      },
    },
    // {
    //   dataField: 'id',
    //   text: 'Actions',
    //   formatter: (cell, row) => {
    //     return (
    //       <label className="table-action" onClick={() => onAction('logs', cell)}>
    //         <i className="fa fa-history" />
    //         Logs
    //       </label>
    //     );
    //   },
    // },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pageSize || 10,
    totalSize: options?.totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
