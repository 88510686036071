export const statisticsInitialState = [
  {
    title: 'success',
    data: {
      all: 0,
      details: {},
    },
  },
  {
    title: 'personal_info_error',
    data: {
      all: 0,
      details: {},
    },
  },
  {
    title: 'doc_error',
    data: {
      all: 0,
      details: {},
    },
  },
  {
    title: 'face_error',
    data: {
      all: 0,
      details: {},
    },
  },
  {
    title: 'address_error',
    data: {
      all: 0,
      details: {},
    },
  },
  {
    title: 'mismatch_error',
    data: {
      all: 0,
      details: {},
    },
  },
  {
    title: 'network_error',
    data: {
      all: 0,
      details: {},
    },
  },
  {
    title: 'unknown',
    data: {
      all: 0,
      details: {},
    },
  },
];

export const useFormatStatistics = (data: Record<string, any>) => {
  if (!data) {
    return [...statisticsInitialState];
  }

  const formatted: { title: string; data: any }[] = [];

  for (const [key, value] of Object.entries(data)) {
    formatted.push({ title: key, data: value });
  }

  return formatted;
};
