import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';

import UiModal from '../../../components/Modal';
import { sendPhoneOTP } from '../../../../shared/services/user';
import extractErrorMessage from '../../../helpers/extractErrorMessage';

const ChangePhoneModal = ({ isOpen, onClose, currentPhone, currentCountryCode, onSave, t }) => {
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState(currentCountryCode);
  const initialValues = {
    newContactNumber: currentPhone,
  };

  const validationSchema = Yup.object().shape({
    newContactNumber: Yup.string()
      .min(9, 'Invalid phone number')
      .max(15, 'Invalid phone number')
      .required(t('Phone number is required')),
  });

  const onSubmit = async values => {
    if (currentPhone === values.newContactNumber && currentCountryCode === countryCode) {
      return onClose();
    }

    setLoading(true);
    try {
      await sendPhoneOTP({ contactNumber: `+${values.newContactNumber}` });
      onSave({
        phoneNumber: values.newContactNumber.substring(countryCode.length - 1),
        countryCode: countryCode,
      });
    } catch (error) {
      let message = extractErrorMessage(error) ?? t('Input must be a valid phone number');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <UiModal
      isOpen={isOpen}
      toggle={onClose}
      body={
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {formik => (
            <Form className="needs-formik" onSubmit={formik.handleSubmit}>
              <div className="text-center mb-3">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light  rounded-circle text-primary h1">
                    <i className="mdi mdi-phone" />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4 className="text-primary">{t('Change Phone Number')}</h4>
                    <p className="text-muted font-size-14 mb-4">{t('An OTP will be sent to your phone number.')}</p>
                    <div className="input-group rounded bg-light">
                      <PhoneInput
                        country={'jo'}
                        enableSearch
                        disableSearchIcon
                        countryCodeEditable={false}
                        containerClass="form-control bg-transparent border-0 m-0 p-0"
                        inputClass="settings-phone-input"
                        searchClass="settings-phone-input-search"
                        dropdownClass="settings-phone-input-dropdown"
                        value={formik.values.newContactNumber}
                        onChange={(phone, country) => {
                          formik.setFieldValue('newContactNumber', phone);
                          setCountryCode(`+${country?.dialCode}`);
                        }}
                      />
                      <button disabled={loading} type="submit" id="button-addon2" className="btn btn-primary">
                        {loading ? (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                        ) : (
                          <i className="bx bxs-paper-plane" />
                        )}
                      </button>
                    </div>
                    {formik.touched.newContactNumber && formik.errors.newContactNumber ? (
                      <p className="font-size-12 mb-0 mt-2 text-left color-danger">{formik.errors.newContactNumber}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default withTranslation()(ChangePhoneModal);
