import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';

import { cbjApi, jopaccApi, participantAPI } from '../../../../shared/api';
import { ENTITY_TYPE } from '../../../constants/domain';
import { getAge } from '../../../helpers/functions';

const DetailsPage = props => {
  const user = useSelector(state => state.app.profile.data);
  const [id, setId] = useState();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { userId } = props.location?.state || {};
    if (!userId && !id) {
      return props.history.goBack();
    } else {
      setId(userId);
    }
  }, [props, id]);

  useEffect(() => {
    if (!id || !user?.entityType) {
      return;
    }

    const fetchConsents = async () => {
      try {
        setLoading(true);
        const response =
          user.entityType === ENTITY_TYPE.PARTICIPANT
            ? await participantAPI.getCustomerDetails(id)
            : user.entityType === ENTITY_TYPE.JOPACC
            ? await jopaccApi.getJopaccCustomerDetails(id)
            : await cbjApi.getCBJCustomerDetails(id);

        const resData = response.data.data;
        if (resData['cspdRecord'] && typeof resData['cspdRecord'] === 'string') {
          resData['cspdRecord'] = JSON.parse(resData['cspdRecord']);
        }

        resData['nationality'] = resData['nationality'] ?? resData['cspdRecord']?.['nationality'];
        resData['gender'] = resData['gender'] ?? resData['cspdRecord']?.['gender'];

        setData(resData);
      } catch (e) {
        toast.error(e?.response?.data?.message || 'Something went wrong');
      }

      setLoading(false);
    };

    fetchConsents();
  }, [id, user.entityType]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('Customer Details')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="d-flex mx-3 mb-3">
              <h4 className="font-size-24">{props.t('Customer Details')}</h4>
            </div>

            <Card>
              <CardBody>
                <Row className="border-bottom border-top py-3">
                  <Col md={3}>{props.t('ID')}</Col>
                  <Col md={9}>{data['id']}</Col>
                </Row>
                {typeof data['activeConsents'] === 'number' && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('Active Consents')}</Col>
                    <Col md={9}>{data['activeConsents']}</Col>
                  </Row>
                )}
                {data['idNumber'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('National ID')}</Col>
                    <Col md={9}>{data['idNumber']}</Col>
                  </Row>
                )}
                {data['firstName'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('First Name')}</Col>
                    <Col md={9}>{data['firstName']}</Col>
                  </Row>
                )}
                {data['surname'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('Surname')}</Col>
                    <Col md={9}>{data['surname'] || data['lastName']}</Col>
                  </Row>
                )}
                {data['email'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('Email')}</Col>
                    <Col md={9}>{data['email']}</Col>
                  </Row>
                )}
                {data['contactNumber'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('Phone')}</Col>
                    <Col md={9}>{data['contactNumber']}</Col>
                  </Row>
                )}
                {data['nationality'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('Nationality')}</Col>
                    <Col md={9}>{data['nationality']}</Col>
                  </Row>
                )}
                {data['gender'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('Gender')}</Col>
                    <Col md={9}>{data['gender']}</Col>
                  </Row>
                )}
                {data['birthDate'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('Age')}</Col>
                    <Col md={9}>{getAge(data['birthDate'])}</Col>
                  </Row>
                )}
                <Row className="border-bottom py-3">
                  <Col md={3}>{props.t('Date Of Issue')}</Col>
                  <Col md={9}>{data['createAt'] ? moment(data['createAt']).format('DD.MM.YYYY, hh:mm A') : '#N/A'}</Col>
                </Row>
                {data['dateOfOperation'] && (
                  <Row className="border-bottom py-3">
                    <Col md={3}>{props.t('Date Of Operation')}</Col>
                    <Col md={9}>{moment(data['dateOfOperation']).format('DD.MM.YYYY, hh:mm A')}</Col>
                  </Row>
                )}
                <Row className="border-bottom py-3">
                  <Col md={3}>{props.t('Last Login')}</Col>
                  <Col md={9}>
                    {data['lastLogin'] ? moment(data['lastLogin']).format('DD.MM.YYYY, hh:mm A') : '#N/A'}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        )}
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(DetailsPage));
