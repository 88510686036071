import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ENTITY_TYPE } from '../../../constants/domain';
import { jopaccApi } from '../../../../shared/api';
import DataTable from '../../../components/DataTable';

import { getDataTableOptions } from './datatable-options';

const onTableAction = (action, data, router, entityType) => {
  if (action === 'consents') {
    router.push(`/${entityType === ENTITY_TYPE.JOPACC ? 'jopacc' : 'cbj'}/participant-consents`, {
      participantId: data,
    });
  } else if (action === 'billing') {
    router.push(`/${entityType === ENTITY_TYPE.JOPACC ? 'jopacc' : 'cbj'}/billing/participant`, data);
  } else if (action === 'reporting') {
    router.push(`/${entityType === ENTITY_TYPE.JOPACC ? 'jopacc' : 'cbj'}/reporting/participant`, {
      participantId: data,
    });
  }
};

const JopaccTierParticipantsPage = props => {
  const {
    entityType,
    userRole: { name: userRole },
  } = useSelector(state => state.app.profile.data);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataTableOptions, setDataTableOptions] = useState(
    getDataTableOptions(
      0,
      10,
      (action, id) => onTableAction(action, id, props.history, entityType),
      entityType,
      userRole,
      props.t
    )
  );

  const tierId = props.location?.state?.tierId;
  const tierName = props.location?.state?.tierName;

  useEffect(() => {
    const fetchData = async () => {
      if (!tierId) {
        props.history.replace('/jopacc/tiers');
      }

      try {
        setLoading(true);
        const response = await jopaccApi.getParticipant(tierId);
        setData(response.data.data);
        setDataTableOptions(
          getDataTableOptions(
            response.data.data.length,
            10,
            (action, id) => onTableAction(action, id, props.history, entityType),
            entityType,
            userRole,
            props.t
          )
        );
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || 'Something went wrong');
      }
      setLoading(false);
    };

    fetchData();
  }, [entityType, props.history, tierId]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('Tier Participants')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="d-flex mx-3 mb-3">
              <h4 className="font-size-24">{`${props.t('Participants')} ${props.t('in')} ${tierName}`}</h4>
            </div>

            <DataTable data={data} options={dataTableOptions} />
          </>
        )}
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(JopaccTierParticipantsPage));
