import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { reportingApi } from '../../../shared/api';

import PeriodBilling from './PeriodBilling';
import PerConsentBilling from './PerConsentBilling';

export const BILLING_TYPES = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  PER_CONSENT: 'per-consent',
  LOADING: 'loading',
};

const BillingPage = ({ location, history, t }) => {
  const user = useSelector(state => state.app.profile);
  const [billingType, setBillingType] = useState(BILLING_TYPES.LOADING);

  let { participantId, participantName } = location?.state || {};
  participantId = user.data.entityType === 'participant' ? user.data.entityId : participantId;
  if (!participantId) {
    history.goBack();
  }

  useEffect(() => {
    if (!participantId) {
      return;
    }

    const getSubType = async () => {
      try {
        const subResponse = await reportingApi.getSubscription(participantId);
        setBillingType(subResponse.data.data.billingType);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || t('error'));
        setBillingType(t('Please reload the page'));
      }
    };

    if (user?.data?.entityType !== 'user') {
      getSubType();
    }
  }, [user, participantId]);

  if (user?.data?.entityType === 'user') {
    return <h3 style={{ margin: 100 }}>{t('Customers have no access to this page')}</h3>;
  }

  switch (billingType) {
    case BILLING_TYPES.LOADING:
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    case BILLING_TYPES.MONTHLY:
    case BILLING_TYPES.YEARLY:
      return <PeriodBilling participantName={user.data.entityType === 'jopacc' ? participantName : ''} />;

    case BILLING_TYPES.PER_CONSENT:
      return <PerConsentBilling participantName={user.data.entityType === 'jopacc' ? participantName : ''} />;

    default:
      return <h3>{billingType}</h3>;
  }
};

export default withRouter(withTranslation()(BillingPage));
