import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';

import { jopaccApi } from '../../../../shared/api';
import { COLORS } from '../../../../shared/constants/colors';
import { CONSENT_STATUS_FILTER } from '../../../../shared/constants/enums';
import ServerDataTable from '../../../components/ServerDataTable';

import { getDataTableOptions } from './datatable-options';

const onTableAction = (action, id, router) => {
  if (action === 'logs') {
    // TODO: goto page
  }
};

const UserConsentsHistory = props => {
  const [loading, setLoading] = useState(false);
  const [queryOptions, setQueryOptions] = useState({
    limit: 10,
    page: 1,
    status: '',
  });
  const [data, setData] = useState([]);
  const [consentsCounters, setConsentsCounters] = useState({
    all: '0',
    pending: '0',
    allowed: '0',
    rejected: '0',
    revoked: '0',
    expired: '0',
  });
  const [pageSize, setPageSize] = useState(10);
  const [dataTableOptions, setDataTableOptions] = useState(
    getDataTableOptions(0, pageSize, (action, id) => onTableAction(action, id, props.history), props.t)
  );

  useEffect(() => {
    const { userId } = props.history?.location?.state || {};

    const fetchConsentsHistory = async () => {
      setLoading(true);
      try {
        const queryObj = Object.keys(queryOptions).reduce((p, c) => {
          if (queryOptions[c]) {
            p = { ...p, [c]: queryOptions[c] };
          }
          return p;
        }, {});

        const response = await jopaccApi.getCustomerConsentsHistory(userId, qs.stringify(queryObj));
        setData(response.data.data.paginatedResult);
        setConsentsCounters(response.data.data.consentsCounts);
        setDataTableOptions(
          getDataTableOptions(
            response.data.options,
            pageSize,
            (action, id) => onTableAction(action, id, props.history),
            props.t
          )
        );
      } catch (error) {
        console.error(error);
        toast.error(error?.response?.data?.message || error?.message || props.t('error'));
      }
      setLoading(false);
    };

    if (!userId) {
      props.history.goBack();
    } else {
      fetchConsentsHistory();
    }
  }, [pageSize, props.history, props.history?.location?.state, queryOptions]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage: limit } = newState;

    setPageSize(limit);
    setQueryOptions({
      ...queryOptions,
      page,
      limit,
    });
  };

  const onFilterClicked = status => {
    if (status !== queryOptions.status) {
      const newStatus = status === CONSENT_STATUS_FILTER.all ? '' : status;
      setQueryOptions({ ...queryOptions, status: newStatus });
    }
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('User Consents History')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        <>
          <div className="d-flex mx-3 mb-3">
            <h4 className="font-size-24">
              {props.t('User Consents History')} {loading && <i className="bx bx-loader bx-spin align-middle me-2" />}
            </h4>
          </div>

          <div className="d-flex">
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={!queryOptions.status ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.all)}>
              {`${props.t('All')} ${consentsCounters.all}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.accepted ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.accepted)}>
              {`${props.t('Approved')} ${consentsCounters.allowed}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.pending ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.pending)}>
              {`${props.t('Pending')} ${consentsCounters.pending}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.rejected ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.rejected)}>
              {`${props.t('Rejected')} ${consentsCounters.rejected}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.revoked ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.revoked)}>
              {`${props.t('Revoked')} ${consentsCounters.revoked}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.expired ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.expired)}>
              {`${props.t('Expired')} ${consentsCounters.expired}`}
            </button>
          </div>

          <ServerDataTable onTableChange={onTableChange} data={data} options={dataTableOptions} withoutSearch />
        </>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(UserConsentsHistory));
