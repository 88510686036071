export const userRoles = {
  jopacc: [
    { label: 'Administrator', value: '7ddf2695-95df-4936-97d9-17c235e46350' },
    { label: 'Manager', value: 'fc9cedab-f8b0-4924-a86c-1895b545f500' },
  ],
  participant: [
    { label: 'Administrator', value: 'f8235cbd-0253-4eb6-af3c-347c8a28a2aa' },
    { label: 'Manager', value: 'ad4af59a-8869-4db5-abbc-02571d6c31cf' },
  ],
  cbj: [
    { label: 'Administrator', value: '2e5d0c3f-ea8e-41a3-b525-7b81670fec45' },
    { label: 'Manager', value: 'b272d360-b539-4e3d-91cd-2083c56054f0' },
  ],
};

export const localizedUserRoles = t => ({
  jopacc: [
    { label: t('Administrator'), value: '7ddf2695-95df-4936-97d9-17c235e46350' },
    { label: t('Manager'), value: 'fc9cedab-f8b0-4924-a86c-1895b545f500' },
  ],
  participant: [
    { label: t('Administrator'), value: 'f8235cbd-0253-4eb6-af3c-347c8a28a2aa' },
    { label: t('Manager'), value: 'ad4af59a-8869-4db5-abbc-02571d6c31cf' },
  ],
  cbj: [
    { label: t('Administrator'), value: '2e5d0c3f-ea8e-41a3-b525-7b81670fec45' },
    { label: t('Manager'), value: 'b272d360-b539-4e3d-91cd-2083c56054f0' },
  ],
});
