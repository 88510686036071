import { participantAPI } from '../../api';
import { IAddParticipantUserRequest, IAddParticipantUserResponse, IParticipantUser } from '../../types/api';

export const addUser = async (user: IAddParticipantUserRequest): Promise<IAddParticipantUserResponse> => {
  // TODO: Keep extra fields and add then to UI or remove and make optional in the api
  user = {
    ...user,
    country: 'Jordan',
  };

  const { data } = await participantAPI.addUser(user);
  return data;
};

export const updateUser = async (userId: number, user: IAddParticipantUserRequest): Promise<any> => {
  return await participantAPI.updateUser(userId, user);
};

export const deleteUser = async (id: number): Promise<any> => {
  return await participantAPI.deleteUser(id);
};

export const getAllUsers = async (): Promise<IParticipantUser[]> => {
  const {
    data: { data },
  } = await participantAPI.getAllUsers();
  return data;
};
