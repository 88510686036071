import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import logo from '../../../shared/assets/images/i_logo.png';
import { getSettings, getUserProfile, signIn } from '../../../shared/services/user';
import { setProfile, setSettings } from '../../../shared/store/reducers/app.reducer';
import { ENTITY_TYPE } from '../../constants/domain';
import './styles.css';

import LanguageDropdown from '../../components/CommonForBoth/TopbarDropdown/LanguageDropdown';
import { setStorageItem } from '../../../shared/utils/secureStorage';
import { STORAGE_ITEMS } from '../../../shared/constants/enums';

const username_prefixes: { [key: string]: { prefix: string; entity: string } } = {
  jopacc: { prefix: 'jopacc#', entity: ENTITY_TYPE.JOPACC },
  participant: { prefix: 'par#', entity: ENTITY_TYPE.PARTICIPANT },
  cbj: { prefix: 'cbj#', entity: ENTITY_TYPE.CBJ },
  user: { prefix: '', entity: ENTITY_TYPE.USER },
};

const Login = ({ history, t, portal }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t('Please Enter Your Username')),
      password: Yup.string().required(t('Please Enter Your Password')),
    }),
    onSubmit: async ({ username, password }) => {
      try {
        setLoading(true);
        await signIn({ username: `${username_prefixes[portal].prefix}${username}`, password });
        const settings = await getSettings();
        setStorageItem(STORAGE_ITEMS.REFRESH_TOKEN_EXPIRE_TIME, JSON.stringify(settings.refreshTokenExpMin));

        const profile = await getUserProfile();
        const type = profile?.data?.entityType;

        if (type !== username_prefixes[portal].entity) {
          setError(t('Suspicious activity detected. User not authorized to access this portal.'));
          setLoading(false);
          return;
        }

        dispatch(setProfile(profile));

        if (type === ENTITY_TYPE.JOPACC || type === ENTITY_TYPE.CBJ) {
          dispatch(setSettings(settings));
        }

        const homePath =
          type === ENTITY_TYPE.PARTICIPANT
            ? '/participant/customers'
            : type === ENTITY_TYPE.CBJ
            ? '/cbj/customers'
            : '/jopacc/customers';
        history.push(homePath);
      } catch (error) {
        setError(
          [400, 401, 404].includes(error?.response?.status)
            ? t('Invalid Credentials')
            : t('Something went wrong. Please try again later.')
        );
      }
      setLoading(false);
    },
  });

  return (
    <>
      <MetaTags>
        <title>{t('Login')} | Jopacc</title>
      </MetaTags>
      <div className="ms-4 mt-4">
        <img src={logo} height="100" className="logo" />
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      <div className="mb-4 mt-4">
                        <Label className="form-label">{t('Username')}</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder={t('Enter username')}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ''}
                          invalid={validation.touched.username && validation.errors.username ? true : false}
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{t('Password')}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ''}
                          type="password"
                          placeholder={t('Enter Password')}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.password && validation.errors.password ? true : false}
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mt-4 d-grid">
                        <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
                          {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />}
                          {t('Login')}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>© {new Date().getFullYear()} Jopacc</p>
              </div>
            </Col>
          </Row>
          <div className="language-btn">
            <LanguageDropdown inline />
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
};
