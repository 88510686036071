import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';

import './settings.scss';
import { getUserProfile, sendEmailOTP, verifyEmailAndSendOTP } from '../../../shared/services/user';
import profileImgPlaceholder from '../../../shared/assets/images/profile_image_placeholder.png';

import ChangePasswordModal from './components/ChangePasswordModal';
import ChangeEmailModal from './components/ChangeEmailModal';
import VerifyEmailModal from './components/VerifyEmailModal';
import ChangePhoneModal from './components/ChangePhoneModal';
import VerifyPhoneModal from './components/VerifyPhoneModal';

const MODALS = {
  EDIT_PHONE: 'editPhone',
  EDIT_EMAIL: 'editEmail',
  EDIT_PASSWORD: 'editPassword',
  VERIFY_EMAIL: 'verifyEmail',
  VERIFY_PHONE: 'verifyPhone',
};

const CustomerUserSettings = ({ t: translate }) => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [visibleModal, setVisibleModal] = useState('');

  const [tempNewEmail, setTempNewEmail] = useState('');
  const [tempNewPhone, setTempNewPhone] = useState(null);

  const [emailVerifyOnly, setEmailVerifyOnly] = useState(false);
  const lang = localStorage.getItem('i18nextLng');
  const userFullName =
    lang === 'ar' && !!profile?.arFName
      ? `${profile.arFName} ${profile.arSName} ${profile.arTName} ${profile.arLName}`
      : `${profile?.firstName} ${profile?.lastName}`;

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const response = await getUserProfile();
      setProfile(response.data);
    } catch (e) {
      toast.error(e?.response?.data?.message || translate('Something went wrong. Please try again later.'));
    }

    setLoading(false);
  };

  const onEmailChangeRequested = newEmailAddress => {
    setTempNewEmail(newEmailAddress);
    setVisibleModal(MODALS.VERIFY_EMAIL);
  };

  const onPhoneChangeRequested = newPhone => {
    setTempNewPhone(newPhone);
    setVisibleModal(MODALS.VERIFY_PHONE);
  };

  const onVerifyEmailRequested = async () => {
    setEmailVerifyOnly(true);
    setTempNewEmail(profile?.email);
    setVisibleModal(MODALS.VERIFY_EMAIL);
    await sendEmailOTP({ email: profile?.email });
  };

  const resetModalStates = () => {
    setTempNewEmail('');
    setTempNewPhone('');
    setVisibleModal('');
  };

  return (
    <div className="page-content settings-page">
      <ChangePasswordModal isOpen={visibleModal === MODALS.EDIT_PASSWORD} onClose={resetModalStates} />
      <ChangeEmailModal
        isOpen={visibleModal === MODALS.EDIT_EMAIL}
        onClose={resetModalStates}
        onSave={onEmailChangeRequested}
        currentEmail={profile?.email}
      />
      <ChangePhoneModal
        isOpen={visibleModal === MODALS.EDIT_PHONE}
        currentCountryCode={profile?.countryCode}
        currentPhone={profile?.contactNumber}
        onClose={resetModalStates}
        onSave={onPhoneChangeRequested}
      />
      <VerifyEmailModal
        isOpen={visibleModal === MODALS.VERIFY_EMAIL}
        onClose={() => {
          resetModalStates();
          fetchProfile();
        }}
        newEmail={tempNewEmail}
        currentEmail={profile?.email}
        verifyOnly={emailVerifyOnly}
      />
      <VerifyPhoneModal
        isOpen={visibleModal === MODALS.VERIFY_PHONE}
        onClose={() => {
          resetModalStates();
          fetchProfile();
        }}
        newPhone={tempNewPhone}
        currentPhone={profile?.contactNumber}
      />

      <MetaTags>
        <title>Profile | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <>
            <Row className="justify-content-center">
              <Col xl="8" sm="9" xs="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="4" xm="12" className="mb-4">
                        <img
                          className="user-profile-img"
                          alt="avatar"
                          src={profile?.selfie ? `data:image/png;base64,${profile?.selfie}` : profileImgPlaceholder}
                        />
                      </Col>
                      <Col sm="8" xm="12">
                        <h4 className="mb-3">{translate('Profile')}</h4>

                        <label className="mt-3 mb-1 font-size-12">{translate('User ID')}</label>
                        <h5 className="m-0">{profile?.id}</h5>

                        <label className="mt-3 mb-1 font-size-12">{translate('Name')}</label>
                        <h5 className="m-0">{userFullName}</h5>

                        <label className="mt-3 mb-1 font-size-12">{translate('National ID')}</label>
                        <h5 className="m-0">{profile?.idNumber}</h5>

                        <label className="mt-3 mb-1 font-size-12">{translate('Country')}</label>
                        <h5 className="m-0">{profile?.country}</h5>

                        <label className="mt-3 mb-1 font-size-12">{translate('Phone Number')}</label>
                        <h5 className="m-0 ltr">{profile?.contactNumber}</h5>
                        {/* <div className="d-flex justify-content-between mt-1">
                          <label className="edit-label color-success">{translate('Verified')}</label>
                          <label
                            className="edit-label color-primary"
                            onClick={() => setVisibleModal(MODALS.EDIT_PHONE)}>
                            Change
                          </label>
                        </div> */}
                        <label className="mt-3 mb-1 font-size-12">{translate('Email Address')}</label>
                        <h5 className="m-0">{profile?.email}</h5>
                        <div className="d-flex justify-content-between mt-1">
                          {profile?.emailVerified ? (
                            <label className="edit-label color-success">{translate('Verified')}</label>
                          ) : (
                            <label className="edit-label color-primary" onClick={onVerifyEmailRequested}>
                              {translate('Verify')}
                            </label>
                          )}
                          <label
                            className="edit-label color-primary"
                            onClick={() => setVisibleModal(MODALS.EDIT_EMAIL)}>
                            {translate('Change')}
                          </label>
                        </div>

                        <Row>
                          <Col md="6">
                            <label className="mt-3 mb-1 font-size-12">{translate('Date of Registration')}</label>
                            <h5 className="m-0 ltr">{moment(profile?.createAt).format('DD.MM.YYYY, hh:mm A')}</h5>
                          </Col>
                          <Col md="6">
                            {profile?.lastLogin && (
                              <>
                                <label className="mt-3 mb-1 font-size-12">{translate('Last Login')}</label>
                                <h5 className="m-0 ltr">{moment(profile?.lastLogin).format('DD.MM.YYYY, hh:mm A')}</h5>
                              </>
                            )}
                          </Col>
                        </Row>

                        {/* <label className="mt-3 mb-1 font-size-12">Password</label>
                        <h6
                          className="m-0 edit-label color-primary"
                          onClick={() => setVisibleModal(MODALS.EDIT_PASSWORD)}>
                          Change Password
                        </h6> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(CustomerUserSettings);
