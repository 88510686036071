import moment from 'moment';
import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ENTITY_TYPE } from '../../../constants/domain';

const GeneralCards = ({ billing, subscription }) => {
  const { t } = useTranslation();
  const entityType = useSelector(state => state.app.profile.data.entityType);
  const isCBJ = entityType === ENTITY_TYPE.CBJ;

  const { totalBilling, tier } = billing;
  const { joined, billingType, fee, totalCustomers } = subscription;

  const reports = [
    {
      title: t('Joined'),
      subtitle: t('Join date to the JoPacc'),
      iconClass: 'bx-copy-alt',
      description: joined !== 'Loading...' ? moment(joined).format('DD MMM YYYY') : t(joined),
    },
    ...(!isCBJ
      ? [
          {
            title: t('Billing Type'),
            subtitle: t('Type of your payments'),
            iconClass: 'bx-archive-in',
            description: t(capitalize(billingType)),
          },
          {
            title: t('Fee'),
            subtitle: t('Fee based on billing type'),
            iconClass: 'bx-purchase-tag-alt',
            description: `${fee} ${t('JDs')}`,
          },
        ]
      : []),
    {
      title: t('Customers'),
      subtitle: t('Total active customers'),
      iconClass: 'bx-purchase-tag-alt',
      description: totalCustomers,
    },
  ];

  const reports_2 = [
    ...(!isCBJ
      ? [
          {
            title: t('Total Billing'),
            subtitle: t('Total invoice since joined JoPacc'),
            iconClass: 'bx-copy-alt',
            description: totalBilling,
          },
        ]
      : []),
    { title: t('Tier'), subtitle: t('Type of Tier'), iconClass: 'bx-archive-in', description: capitalize(tier) },
  ];

  return (
    <>
      <Row>
        {reports.map((report, key) => (
          <Col md="12" xxl={isCBJ ? '6' : '3'} key={'_col_' + key}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">{report.subtitle}</p>
                    <h4 className="mb-0">{report.description}</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        {reports_2.map((report, key) => (
          <Col md={isCBJ ? '12' : '6'} key={'_col_' + key}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">{report.subtitle}</p>
                    <h4 className="mb-0">{report.description}</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GeneralCards;
