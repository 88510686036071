import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConsentActions, CurrentStageEnum, EDocType, LANGUAGES, STATE_STATUSES } from '../../constants/enums';
import {
  IBigChainConsent,
  IBigChainConsentsResponse,
  ICheckDocumentRequest,
  ICheckDocumentResponse,
  // IConsentsResponse,
  IEnrollmentResponse,
  ISignInResponse,
  IUserAddressResponse,
  IUserProfileResponse,
  WebAppConfigResponse,
} from '../../types/api';

export interface IAppState {
  docType: EDocType | null;
  selfie: ISelfieState | null;
  currentStage: CurrentStageEnum;
  enrollmentStatus: IEnrollment | null;
  profile: IUserProfileResponse | null;
  address: IUserAddressResponse | null;
  MRZData: ICheckDocumentResponse | null;
  documentPhotos: ICheckDocumentRequest | null;
  userContacts: IUserContacts | null;
  newUserContacts: INewUserContacts | null;
  authData: ISignInResponse | null;
  consentStatus: STATE_STATUSES;
  profileStatus: STATE_STATUSES;
  consents: IBigChainConsentsResponse | null;
  ekycAuthorized: boolean;
  idNumber?: string;
  changePasswordForgotMode: boolean;
  showNetworkError: boolean;
  language: LANGUAGES;
  selectedConsent: ISelectedConsent;
  settings: {
    webAppCbjLogo: string;
    webAppJopaccLogo: string;
  };
}

export interface ISelectedConsent {
  consent: IBigChainConsent;
  action: ConsentActions;
}

export interface IDocumentPhotos {
  front: IImageState;
  back: IImageState;
}

export interface IImageState {
  path: string;
  base64: string;
}

export interface ISelfieState {
  photos: string[];
}

export interface IEnrollment {
  data?: IEnrollmentResponse;
  success: boolean;
}

export interface IUserContacts {
  email: string;
  phoneNumber: string;
  countryCode: string;
}

export interface INewUserContacts {
  newPhoneNumber?: string;
  newCountryCode?: string;
}

const initialState: IAppState = {
  docType: null,
  selfie: null,
  currentStage: CurrentStageEnum.Welcome,
  enrollmentStatus: null,
  profile: null,
  address: null,
  MRZData: null,
  documentPhotos: null,
  userContacts: null,
  newUserContacts: null,
  authData: null,
  consentStatus: STATE_STATUSES.UPDATED,
  profileStatus: STATE_STATUSES.UPDATED,
  consents: null,
  idNumber: undefined,
  ekycAuthorized: false,
  changePasswordForgotMode: false,
  showNetworkError: false,
  language: LANGUAGES.EN,
  selectedConsent: undefined,
  settings: {
    webAppCbjLogo: '',
    webAppJopaccLogo: '',
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<WebAppConfigResponse>) => {
      return {
        ...state,
        settings: action.payload,
      };
    },
    setProfile: (state, action: PayloadAction<IUserProfileResponse>) => {
      return {
        ...state,
        profile: action.payload,
      };
    },
    setAddress: (state, action: PayloadAction<IUserAddressResponse>) => {
      return {
        ...state,
        address: action.payload,
      };
    },
    setMRZData: (state, action: PayloadAction<ICheckDocumentResponse>) => {
      return {
        ...state,
        MRZData: action.payload,
      };
    },
    setSelfie: (state, action: PayloadAction<ISelfieState>) => {
      return {
        ...state,
        selfie: action.payload,
        currentStage: CurrentStageEnum.Results,
      };
    },
    setDocType: (state, action: PayloadAction<EDocType>) => {
      return {
        ...state,
        docType: action.payload,
      };
    },
    setCurrentStage: (state, action: PayloadAction<CurrentStageEnum>) => {
      return {
        ...state,
        currentStage: action.payload,
      };
    },
    setEnrollmentStatus: (state, action: PayloadAction<IEnrollment>) => {
      return {
        ...state,
        enrollmentStatus: action.payload,
      };
    },
    setDocumentPhotos: (state, action: PayloadAction<ICheckDocumentRequest | null>) => {
      return {
        ...state,
        documentPhotos: action.payload,
      };
    },
    setUserContacts: (state, action: PayloadAction<IUserContacts>) => {
      return {
        ...state,
        userContacts: action.payload,
      };
    },
    setNewUserContacts: (state, action: PayloadAction<INewUserContacts>) => {
      return {
        ...state,
        newUserContacts: action.payload,
      };
    },
    setAuthData: (state, action: PayloadAction<ISignInResponse>) => {
      return {
        ...state,
        authData: action.payload,
      };
    },
    setConsentStatus: (state, action: PayloadAction<STATE_STATUSES>) => {
      return {
        ...state,
        consentStatus: action.payload,
      };
    },
    setProfileStatus: (state, action: PayloadAction<STATE_STATUSES>) => {
      return {
        ...state,
        profileStatus: action.payload,
      };
    },
    setConsents: (state, action: PayloadAction<IBigChainConsentsResponse>) => {
      return {
        ...state,
        consents: action.payload,
      };
    },
    setEKYCAuthorized: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        ekycAuthorized: action.payload,
      };
    },
    setIdNumber: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        idNumber: action.payload,
      };
    },
    setForgotPasswordMode: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        changePasswordForgotMode: action.payload,
      };
    },
    clearSession: () => {
      return initialState;
    },
    setShowNetworkError: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showNetworkError: action.payload,
      };
    },
    setLanguage: (state, action: PayloadAction<LANGUAGES>) => {
      return {
        ...state,
        language: action.payload,
      };
    },
    saveSelectedConsent: (state, action: PayloadAction<ISelectedConsent>) => {
      return {
        ...state,
        selectedConsent: action.payload,
      };
    },
  },
});

export const {
  setProfile,
  setAddress,
  clearSession,
  setMRZData,
  setDocType,
  setSelfie,
  setCurrentStage,
  setEnrollmentStatus,
  setDocumentPhotos,
  setUserContacts,
  setNewUserContacts,
  setAuthData,
  setConsentStatus,
  setConsents,
  setEKYCAuthorized,
  setProfileStatus,
  setIdNumber,
  setForgotPasswordMode,
  setShowNetworkError,
  setLanguage,
  saveSelectedConsent,
  setSettings,
} = appSlice.actions;

export default appSlice.reducer;
