import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';

import { jopaccApi } from '../../../../shared/api';
import ServerDataTable from '../../../components/ServerDataTable';

import { getDataTableOptions } from './datatable-options';
import SearchBar from './SearchBar';

const onTableAction = (action, id, router) => {
  if (action === 'history') {
    router.push('/jopacc/customers/history', { userId: id });
  } else if (action === 'details') {
    router.push('/customers/details', { userId: id });
  }
};

const JopaccConsentsPage = props => {
  const [loading, setLoading] = useState(false);
  const [queryOptions, setQueryOptions] = useState({
    limit: 10,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [dataTableOptions, setDataTableOptions] = useState(
    getDataTableOptions(0, pageSize, (action, id) => onTableAction(action, id, props.history), props.t)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Remove empty filters/options from qs
        const queryObj = Object.keys(queryOptions).reduce((p, c) => {
          if (queryOptions[c]) {
            p = { ...p, [c]: queryOptions[c] };
          }
          return p;
        }, {});

        const response = await jopaccApi.getUserConsents(qs.stringify(queryObj));
        setData(response.data.data);
        setDataTableOptions(
          getDataTableOptions(
            response.data.options,
            pageSize,
            (action, id) => onTableAction(action, id, props.history),
            props.t
          )
        );
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || 'Something went wrong');
      }
      setLoading(false);
    };

    fetchData();
  }, [queryOptions, pageSize, props.history]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage: limit } = newState;

    setPageSize(limit);
    setQueryOptions({
      ...queryOptions,
      page,
      limit,
    });
  };

  const onFilterApplied = filter => {
    setQueryOptions({
      ...queryOptions,
      ...filter,
    });
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Customers | Jopacc</title>
      </MetaTags>
      <Container fluid>
        <>
          <div className="d-flex mx-3 mb-3">
            <h4 className="font-size-24">
              {/* {props.t('Customers')} {loading && <i className="bx bx-loader bx-spin align-middle me-2" />} */}
            </h4>
          </div>

          <ServerDataTable
            onTableChange={onTableChange}
            data={data}
            options={dataTableOptions}
            withoutSearch
            headerComponent={<SearchBar loading={loading} onApply={onFilterApplied} />}
          />
        </>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(JopaccConsentsPage));
