import React, { useState } from 'react';
import Select from 'react-select';
import { Row, Form, Input, FormFeedback, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import UiModal from '../../../components/Modal';
import { updateUser } from '../../../../shared/services/userManagement/participantUsers';
import { updateJopaccUser } from '../../../../shared/services/userManagement/jopaccUsers';
import { updateCBJUser } from '../../../../shared/services/userManagement/cbjUsers';
import { ENTITY_TYPE } from '../../../constants/domain';

import { localizedUserRoles } from './constants';
import extractErrorMessage from '../../../helpers/extractErrorMessage';

const EditUserModal = ({ isOpen, toggle, user, onEditCallback = undefined, onErrorCallback = undefined, t }) => {
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(state => state.app.profile.data);
  const [userRoleOptions, setUserRoleOptions] = useState(localizedUserRoles(t)[currentUser.entityType]);

  const initialValues = {
    // idNumber: user?.idNumber,
    firstName: user?.firstName,
    lastName: user?.lastName,
    surname: user?.surname,
    username: user?.username.split('#')[1],
    password: user?.password,
    confirmPassword: user?.password,
    email: user?.email,
    userRoleId: user?.userRole?.id,
  };

  const validationSchema = Yup.object().shape({
    // idNumber: Yup.number().nullable(),
    firstName: Yup.string()
      .matches(/^[A-Za-z]([A-Za-z.\s-]*[A-Za-z])*$/, t('This field can only contain letters, hyphen and period'))
      .nullable(),
    // lastName: Yup.string().required('This value is required'),
    surname: Yup.string()
      .matches(/^[A-Za-z]([A-Za-z.\s-]*[A-Za-z])*$/, t('This field can only contain letters, hyphen and period'))
      .nullable(),
    username: Yup.string()
      .min(8, t('Username must be at least 8 characters long'))
      .max(30, t('Username must be at most 30 characters long'))
      .matches(/^[A-Za-z]([A-Za-z0-9.]*[A-Za-z0-9])*$/, t('Username can only contain letter, numbers and period'))
      .nullable(),
    password: Yup.string()
      .min(8, t('Password must be at least 8 characters long'))
      .max(100, t('Password must be at most 100 characters'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t('Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
      )
      .nullable(),
    confirmPassword: Yup.string().when('password', {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], t('Both passwords need to be the same')),
    }),
    email: Yup.string().email(t('Must be a valid Email')).max(255).nullable(),
    userRoleId: Yup.string().required(t('Please select a role')),
  });

  const onSubmit = async values => {
    try {
      setLoading(true);
      delete values.confirmPassword;
      values.username = values.username.trim();

      if (!values.username || values.username === user?.username.split('#')[1]) {
        delete values['username'];
      }

      Object.keys(values).forEach(key => {
        if (!values[key] || values[key] === user[key]) {
          delete values[key];
        }
      });

      if (Object.keys(values).length > 0) {
        if (currentUser.entityType === ENTITY_TYPE.JOPACC) {
          await updateJopaccUser(user.id, values);
        } else if (currentUser.entityType === ENTITY_TYPE.PARTICIPANT) {
          await updateUser(user.id, values);
        } else if (currentUser.entityType === ENTITY_TYPE.CBJ) {
          await updateCBJUser(user.id, values);
        } else {
          if (typeof onErrorCallback === 'function') {
            onErrorCallback("Couldn't validate current user");
          }
          return;
        }
      }

      toast.success(t('User updated successfully'));
      setLoading(false);
      if (typeof onEditCallback === 'function') {
        onEditCallback(values);
      }
    } catch (error) {
      if (typeof onErrorCallback === 'function') {
        onErrorCallback(error);
      }

      let message = extractErrorMessage(error) ?? t('error');
      toast.error(t(message));
    }

    setLoading(false);
  };

  return (
    <UiModal
      isOpen={isOpen}
      toggle={toggle}
      title={t('Update User')}
      body={
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {formik => (
            <Form className="needs-formik" onSubmit={formik.handleSubmit}>
              {/* <Row className="mb-3">
                <label className="col-md-4 col-form-label">National ID</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('National ID')}
                    name="idNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.idNumber || ''}
                    invalid={formik.touched.idNumber && formik.errors.idNumber ? true : false}
                  />
                  {formik.touched.idNumber && formik.errors.idNumber ? (
                    <FormFeedback type="invalid">{formik.errors.idNumber}</FormFeedback>
                  ) : null}
                </div>
              </Row> */}
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('First Name')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('First Name')}
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName || ''}
                    invalid={formik.touched.firstName && formik.errors.firstName ? true : false}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <FormFeedback type="invalid">{formik.errors.firstName}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              {/* <Row className="mb-3">
                <label className="col-md-4 col-form-label">Last Name</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('Last Name')}
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName || ''}
                    invalid={formik.touched.lastName && formik.errors.lastName ? true : false}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <FormFeedback type="invalid">{formik.errors.lastName}</FormFeedback>
                  ) : null}
                </div>
              </Row> */}
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Surname')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('Surname')}
                    name="surname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.surname || ''}
                    invalid={formik.touched.surname && formik.errors.surname ? true : false}
                  />
                  {formik.touched.surname && formik.errors.surname ? (
                    <FormFeedback type="invalid">{formik.errors.surname}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Email')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="email"
                    placeholder={t('Email')}
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email || ''}
                    invalid={formik.touched.email && formik.errors.email ? true : false}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Username')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('Username')}
                    name="username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username || ''}
                    invalid={formik.touched.username && formik.errors.username ? true : false}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <FormFeedback type="invalid">{formik.errors.username}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Password')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password || ''}
                    invalid={formik.touched.password && formik.errors.password ? true : false}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Confirm Password')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="password"
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword || ''}
                    invalid={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <FormFeedback type="invalid">{formik.errors.confirmPassword}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row>
                <label className="col-md-4 col-form-label">{t('Select Role')}</label>
                <div className="col-md-8">
                  <Select
                    name="userRoleId"
                    placeholder={t('Select Role')}
                    options={userRoleOptions}
                    className={formik.errors.userRoleId ? 'is-invalid' : ''}
                    value={userRoleOptions?.find(option => option.value === formik.values.userRoleId)}
                    onChange={option => formik.setFieldValue('userRoleId', option.value)}
                  />
                  {formik.touched.userRoleId && formik.errors.userRoleId ? (
                    <FormFeedback type="invalid">{formik.errors.userRoleId}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <div className="modal-footer mt-4 pb-0">
                <Button disabled={loading} type="button" color="basic" onClick={toggle}>
                  {t('Cancel')}
                </Button>
                <Button disabled={loading} type="submit" color="primary">
                  {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />} {t('Submit')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default withTranslation()(EditUserModal);
