import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { StatisticsOnboardingData } from '../reporting.types';

// import OnboardingModal from './OnboardingModal';

type Props = {
  data: StatisticsOnboardingData;
};

const OnboardingStatisticsCards = ({ data }: Props) => {
  // const [onboardingOpen, onboardingModalOpen] = useState(false);
  // const [selectedDetails, setSelectedDetails] = useState({});

  const { t } = useTranslation();
  const { success, personal_info_error, doc_error, address_error, network_error } = data;

  const reports = [
    {
      title: t('Successful onboarding'),
      description: success.all,
      // details: success.details,
    },
    {
      title: t('Reached the maximum errors'),
      description: personal_info_error.all,
      // details: personal_info_error.details,
    },
    {
      title: t('Document verification errors'),
      description: doc_error.all,
      // details: doc_error.details,
    },
    // {
    //   title: t('Biometrics verification errors'),
    //   description: face_error.all,
    //   // details: face_error.details,
    // },
    {
      title: t('Address information errors'),
      description: address_error.all,
      // details: address_error.details,
    },
    {
      title: t('Network connection errors'),
      description: network_error.all,
      // details: network_error.details,
    },
    // {
    //   title: t('Unkown errors'),
    //   description: unknown.all,
    //   // details: unknown.details,
    // },
  ];

  // const resetModalStates = (withReload = false) => {
  //   return async () => {
  //     onboardingModalOpen(false);
  //   };
  // };

  return (
    <Row className="mb-4">
      {reports.map((report, key) => (
        <Col md="12" xxl key={'_col_' + key} className="mb-4">
          <Card className="mini-stats-wid h-100">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted text-truncate fw-medium">{report.title}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">{report.description}</h4>
                    {/* <div className="cursor-pointer">
                      <i
                        className="fa fa-eye "
                        onClick={() => {
                          onboardingModalOpen(true);
                          setSelectedDetails(report.details);
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
      {/* <OnboardingModal
        isOpen={onboardingOpen}
        onAddedCallback={resetModalStates(true)}
        toggle={resetModalStates()}
        details={selectedDetails}
      /> */}
    </Row>
  );
};

export default OnboardingStatisticsCards;
