import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import './index.scss';

const ServerDataTable = ({
  data,
  options,
  onTableChange,
  withoutSearch = false,
  headerComponent = null,
  keyField = 'id',
}) => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <PaginationProvider pagination={paginationFactory(options.pageOptions)}>
                  {({ paginationTableProps }) => (
                    <ToolkitProvider
                      keyField={keyField ?? 'id'}
                      columns={options.columns}
                      data={data}
                      search={!withoutSearch}>
                      {toolkitProps => (
                        <React.Fragment>
                          {!!headerComponent && (
                            <Row>
                              <Col xl="12">{headerComponent}</Col>
                            </Row>
                          )}
                          <Row>
                            {!withoutSearch && (
                              <Col xl="12">
                                {/* TODO: Replace this search input with custom made one */}
                                {/* TODO: Search.SearchBar has issues with debounce */}
                                <Search.SearchBar {...toolkitProps.searchProps} delay={1500} />
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                remote={{ pagination: true, search: true, sort: true }}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={options.defaultSorted}
                                selectRow={options.selectRow}
                                classes={'table align-middle table-nowrap'}
                                headerWrapperClasses={'thead-light'}
                                onTableChange={onTableChange}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                keyField={keyField ?? 'id'}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ServerDataTable;
