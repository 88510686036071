import moment from 'moment';
import React from 'react';

import { getAge } from '../../../helpers/functions';

export const getDataTableOptions = (options, pageSize, onAction, t) => ({
  columns: [
    {
      dataField: 'id',
      text: t('ID'),
      sort: false,
    },
    {
      dataField: 'consentsCount',
      text: t('Active Consents'),
      sort: false,
    },
    {
      dataField: 'nationality',
      text: t('Nationality'),
      sort: false,
    },
    {
      dataField: 'gender',
      text: t('Gender'),
      sort: false,
    },
    {
      dataField: 'birthDate',
      text: t('Age'),
      formatter: (cell, row) => {
        return getAge(cell);
      },
      sort: false,
    },
    {
      dataField: 'createAt',
      text: t('Date of Onboarding'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'lastLogin',
      text: t('Last Login'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'id',
      text: t('Actions'),
      formatter: (cell, row) => {
        return (
          <>
            <label className="table-action" onClick={() => onAction('history', cell)}>
              <i className="fa fa-history" />
              {t('Consent History')}
            </label>
            <label className="table-action" onClick={() => onAction('details', cell)}>
              <i className="fa fa-eye" />
              {t('Customer Details')}
            </label>
          </>
        );
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pageSize || 10,
    totalSize: options?.totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
