import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';

import ServerDataTable from '../../../components/ServerDataTable';
import { cbjApi, jopaccApi } from '../../../../shared/api';
import { CONSENT_STATUS_FILTER } from '../../../../shared/constants/enums';
import { COLORS } from '../../../../shared/constants/colors';
import { ENTITY_TYPE } from '../../../constants/domain';

import { getDataTableOptions } from './datatable-options';
import SearchBar from './SearchBar';

const onTableAction = (action, id, router) => {
  if (action === 'logs') {
    // TODO: goto page
  }
};

const JopaccParticipantConsents = props => {
  const user = useSelector(state => state.app.profile.data);
  const [participantId, setParticipantId] = useState();
  const [loading, setLoading] = useState(false);
  const [queryOptions, setQueryOptions] = useState({
    limit: 10,
    page: 1,
    status: CONSENT_STATUS_FILTER.accepted,
  });
  const [data, setData] = useState([]);
  const [consentsCounters, setConsentsCounters] = useState({
    all: '0',
    pending: '0',
    allowed: '0',
    rejected: '0',
    revoked: '0',
    expired: '0',
  });
  const [pageSize, setPageSize] = useState(10);
  const [dataTableOptions, setDataTableOptions] = useState(
    getDataTableOptions(
      0,
      pageSize,
      (action, id) => onTableAction(action, id, props.history),
      user.entityType,
      queryOptions.status,
      props.t
    )
  );

  useEffect(() => {
    let id = props.history?.location?.state?.participantId;
    if (!id) {
      props.history.goBack();
    } else {
      setParticipantId(id);
    }
  }, [props.history, props.history?.location?.state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!participantId) return;

        setLoading(true);
        // Removing empty filters/options from qs
        const queryObj = Object.keys(queryOptions).reduce((p, c) => {
          if (queryOptions[c]) {
            p = { ...p, [c]: queryOptions[c] };
          }
          return p;
        }, {});

        const response =
          user.entityType === ENTITY_TYPE.JOPACC
            ? await jopaccApi.getParticipantUserConsents(participantId, qs.stringify(queryObj))
            : await cbjApi.getParticipantUserConsents(participantId, qs.stringify(queryObj));
        const normalizedConsents = [];
        for (const user of response.data.data.users) {
          for (const consent of user.consents) {
            normalizedConsents.push({
              ...user,
              ...consent,
              // TODO: decide on how to display the ID => currently very long to display fully
              // TODO2: decide whether to show user or consent id
              // id: consent.id.substring(0, 10).concat('...'),
              id: user.id,
            });
          }
        }

        setData(normalizedConsents);
        setConsentsCounters(response.data.data.consentsCounts);
        setDataTableOptions(
          getDataTableOptions(
            response.data.options,
            pageSize,
            (action, id) => onTableAction(action, id, props.history),
            user.entityType,
            queryOptions.status,
            props.t
          )
        );
      } catch (error) {
        console.log(error);
        toast.error(props.t('error'));
      }
      setLoading(false);
    };

    fetchData();
  }, [queryOptions, pageSize, props.history, participantId, user.entityType]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage: limit } = newState;

    setPageSize(limit);
    setQueryOptions({
      ...queryOptions,
      page,
      limit,
    });
  };

  const onFilterApplied = filter => {
    setQueryOptions({
      ...queryOptions,
      ...filter,
    });
  };

  const onFilterClicked = status => {
    if (status !== queryOptions.status) {
      setQueryOptions({ ...queryOptions, status: status });
    }
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('Participant Customers')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        <>
          <div className="d-flex mx-3 mb-3">
            <h4 className="font-size-24">
              {props.t('Participant Customers')} {loading && <i className="bx bx-loader bx-spin align-middle me-2" />}
            </h4>
          </div>

          <div className="d-flex">
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.accepted ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.accepted)}>
              {`${props.t('Approved')} ${consentsCounters.allowed}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.pending ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.pending)}>
              {`${props.t('Pending')} ${consentsCounters.pending}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.rejected ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.rejected)}>
              {`${props.t('Rejected')} ${consentsCounters.rejected}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.revoked ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.revoked)}>
              {`${props.t('Revoked')} ${consentsCounters.revoked}`}
            </button>
            <button
              className={'btn btn-sm px-3 font-size-14 header-item'}
              style={queryOptions.status === CONSENT_STATUS_FILTER.expired ? { color: COLORS.yellow } : {}}
              type="button"
              onClick={() => onFilterClicked(CONSENT_STATUS_FILTER.expired)}>
              {`${props.t('Expired')} ${consentsCounters.expired}`}
            </button>
          </div>

          <ServerDataTable
            onTableChange={onTableChange}
            data={data}
            options={dataTableOptions}
            withoutSearch
            headerComponent={<SearchBar loading={loading} onApply={onFilterApplied} />}
          />
        </>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(JopaccParticipantConsents));
