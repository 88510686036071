import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import './index.scss';

const DataTable = ({ data, options }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <PaginationProvider pagination={paginationFactory(options.pageOptions)} keyField="id">
                  {({ paginationTableProps }) => (
                    <ToolkitProvider keyField="id" columns={options.columns} data={data} search>
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <Search.SearchBar {...toolkitProps.searchProps} placeholder={t('Search')} />
                            </Col>
                            <Col xl="12">
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                keyField={'id'}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={options.defaultSorted}
                                selectRow={options.selectRow}
                                classes={'table align-middle table-nowrap'}
                                headerWrapperClasses={'thead-light'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

DataTable.propTypes = {
  data: PropTypes.array,
  options: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        dataField: PropTypes.string,
        text: PropTypes.string,
        sort: PropTypes.bool,
      })
    ),
    defaultSorted: PropTypes.arrayOf(
      PropTypes.shape({
        dataField: PropTypes.string,
        order: PropTypes.oneOf(['asc', 'desc']),
      })
    ),
    pageOptions: PropTypes.shape({
      sizePerPage: PropTypes.number,
      totalSize: PropTypes.number,
      custom: PropTypes.bool,
    }),
    selectRow: PropTypes.shape({
      mode: PropTypes.string,
      hideSelectColumn: PropTypes.bool,
    }),
  }),
};

export default DataTable;
