import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Table } from 'reactstrap';

const errorNames = {
  success: 'Successful onboarding',

  max_email: 'Email (maximum number of allowed accounts reached)',
  max_device: 'Device (maximum number of allowed accounts reached)',
  max_phone: 'Mobile (maximum number of allowed accounts reached)',

  doc_expired: 'Expired data of the document',
  doc_cspd_mismatch: 'Data mismatch in CSPD',
  doc_cspd_connection: 'Failed to connect to CSPD',
  doc_liveness: 'Liveness check failed',
  doc_regula: 'Regula liveness verification unsuccessful',
  doc_unacceptable_copy: 'Copy of the document is not acceptable.',
  doc_scan_status: 'Scan status is not valid.',
  doc_unacceptable: 'Document is not acceptable',

  face_liveness: 'Liveness check unsuccessful',
  face_spoof: 'Spoofing attack',
  face_low_score: 'Low score of submitted images (preferred with matching score)',

  address_issue: 'Address information is not added',

  net_personal_info: 'Adding personal information (email, mobile number)',
  net_doc_verification: 'Document verification',
  net_face_liveness_verification: 'Face verification (liveness) ',
  net_face_matching_verification: 'Face verification (matching)',
  net_address_info: 'Address information',

  unknown: 'other',

  id_number_exists: 'id number already exists',
  username_exists: 'user name already exists',
  face_not_matched: 'face not matching',
};

const StatisticDetails = ({ data, t }) => {
  return (
    <Col xl="12" md="12">
      <div className="container-fluid bg-white pb-1">
        <Table striped responsive>
          <thead>
            <tr>
              <th> </th>
              <th>{t('Jopacc application')}</th>
              <th>{t('Participant application IOS')}</th>
              <th>{t('Participant application Android')}</th>
              <th>{t('Other')}</th>
              <th>{t('Total')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, key) => (
              <tr key={key}>
                <th scope="row" width={180}>
                  {t(errorNames[item.title])}
                </th>
                <td width={150}>{item.data['JOPACC-APP']}</td>
                <td width={150}>{item.data['PARTICIPANT-SDK-IOS']}</td>
                <td width={150}>{item.data['PARTICIPANT-SDK-ANDROID']}</td>
                <td width={150}>{item.data['PARTICIPANT-SDK-UNKNOWN']}</td>
                <td width={150}>{item.data['all']}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
};

export default withTranslation()(StatisticDetails);
