import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Row, Spinner } from 'reactstrap';

import { jopaccApi } from '../../../../shared/api';
import { verificationStatisticsInitialState } from '../hooks/verificationStatisticsInitialState';

import VerificationDetails from './VerificationDetails';
import VerificationCards from './verificationCards';

const VerificationStatistics = props => {
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [statistics, setStatistics] = useState(verificationStatisticsInitialState);
  const [displayed, setDisplayed] = useState('0');

  const getCards = (data: Record<string, any>) => {
    // GET CARDS
    let cards: any[] = [];
    for (let [key, value] of Object.entries(data)) {
      const { all, ...rest } = value;
      cards.push({
        title: key,
        all: all || 0,
        data: rest,
      });
    }

    return cards;
  };

  const cards = useMemo(() => getCards(statistics), [statistics]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const queryObj = {
        ...(dateRange[0]
          ? {
              dateFrom: moment(dateRange[0]).format('YYYY-MM-DD'),
              dateTo: moment(dateRange[1]).format('YYYY-MM-DD'),
              diff: '20',
            }
          : {}),
      };

      const statisticsResponse = await jopaccApi.getVerificationStatistics(qs.stringify(queryObj));
      const data = statisticsResponse.data;
      setStatistics(data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || props.t('error'));
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, []);

  useEffect(() => {
    if ((dateRange[0] && dateRange[1]) || (!dateRange[0] && !dateRange[1])) {
      setLoading(true);
      fetchData();
      setLoading(false);
    }
  }, [dateRange]);

  return (
    <div className="container-fluid" style={{ marginTop: '4rem' }}>
      {loading ? (
        <div className="loading">
          <Spinner />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="d-flex mb-3">
            <h4 className="font-size-24">{props.t('Verification statistics')}</h4>
          </div>

          <Row>
            <div className="d-flex mb-3 mt-4 justify-content-end align-items-end">
              <Col md="4" xl="3">
                <label>{props.t('Verification Period')}</label>
                <DatePicker
                  selectsRange={true}
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  onChange={setDateRange}
                  isClearable={true}
                  clearButtonClassName="datepicker-clear-button"
                  wrapperClassName="datepicker-wrapper"
                />
              </Col>
            </div>
          </Row>
          <Row>
            <Col xl="12">
              <VerificationCards data={cards} displayed={displayed} handleClick={setDisplayed} />
              <VerificationDetails data={cards[displayed].data} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(VerificationStatistics);
