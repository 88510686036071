import React from 'react';
import { Redirect } from 'react-router-dom';

import './styles.css';
import AdminLogin from './AdminLogin';
import UserLogin from './UserLogin';
import { env } from '../../../env';

// Set to place holder and will be replaced before runtime by ./entrypoint.sh
//const portal = '{REACT_APP_PORTAL}'; // process.env.REACT_APP_PORTAL || 'user';
// const portal = process.env.REACT_APP_PORTAL;
//const portal = 'user';

const getPortlFromHost = () => {
  const { host } = window.location;
  const subDomain = host.split('.')?.[0]?.trim().toLowerCase() || '';
  if (['jopacc', 'cbj', 'participant'].includes(subDomain)) return subDomain;
  return 'user';
};

const portal = env.REACT_APP_PORTAL || 'user';
console.log(env.REACT_APP_PORTAL);
// const portal = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PORTAL : getPortlFromHost();

const Login = () => {
  return (
    <>
      {portal === 'user' && location.pathname !== '/login' && <Redirect to={`/login`} />}
      {portal !== 'user' && location.pathname !== `/${portal}/login` && <Redirect to={`/${portal}/login`} />}

      {portal === 'user' ? <UserLogin /> : <AdminLogin portal={portal} />}
    </>
  );
};

export default Login;
