import { api } from '../../api';
import { STORAGE_ITEMS } from '../../constants/enums';
import { store } from '../../store';
import { clearSession } from '../../store/reducers/app.reducer';
import {
  ISignInRequest,
  ISignInResponse,
  IUpdateEmailRequest,
  IUpdatePasswordRequest,
  IUpdatePhoneRequest,
  IUserProfileResponse,
  WebAppConfigResponse,
} from '../../types/api';
import { clearSecureStorage, getStorageItem, setStorageItem } from '../../utils/secureStorage';

export const cleanStorage = async () => {};

export const getParticipantKey = async () => {
  const response = await api.getParticipantKey();
  setStorageItem(STORAGE_ITEMS.PARTICIPANT_KEY, response.data);
};

export const signIn = async (payload: ISignInRequest) => {
  const response = await api.singIn(payload);
  setStorageItem(STORAGE_ITEMS.AUTH_DATA, JSON.stringify(response.data));
};

export const logout = async () => {
  const { authentication } = getStorageItem(STORAGE_ITEMS.AUTH_DATA) as ISignInResponse;
  try {
    await api.logout(authentication);
  } finally {
    store.dispatch(clearSession());
    setStorageItem(STORAGE_ITEMS.AUTH_DATA, '');
    clearSecureStorage();
  }
};

export const getUserProfile = async (): Promise<IUserProfileResponse> => {
  const { data } = await api.profile();
  return data;
};

export const getSettings = async (): Promise<WebAppConfigResponse> => {
  const { data } = await api.settings();
  return data;
};

export const updatePhoneNumber = async (payload: IUpdatePhoneRequest) => {
  await api.updatePhoneNumber(payload);
};

export const updateEmail = async (payload: IUpdateEmailRequest) => {
  await api.updateEmail(payload);
};

export const changePassword = async (payload: IUpdatePasswordRequest) => {
  await api.changePassword(payload);
};

export const verifyEmailAndSendOTP = async (payload: { email: string }) => {
  await api.sendEmailOTP({ ...payload, isAuth: false });
  await api.verifyContactDetails(payload);
};

export const sendEmailOTP = async (payload: { email: string }) => {
  await api.sendEmailOTP({ ...payload, isAuth: false });
};

export const sendPhoneOTP = async (payload: { contactNumber: string }) => {
  await api.sendOTP({ ...payload, isAuth: false });
};

export const deleteUser = async () => {};
