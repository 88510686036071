import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row, Spinner } from 'reactstrap';

import { jopaccApi } from '../../../shared/api';

import StatisticsCards from './components/StaticsCards';
import StatisticDetails from './components/StatisticDetails';
import VerificationStatistics from './components/VerificationStatistics';
import { statisticsInitialState, useFormatStatistics } from './hooks/useFormatStatistics';

export const Statistics = props => {
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [statistics, setStatistics] = useState([...statisticsInitialState]);
  const [displayed, setDisplayed] = useState('0');

  const fetchData = async () => {
    try {
      setLoading(true);
      const queryObj = {
        ...(dateRange[0]
          ? {
              dateFrom: moment(dateRange[0]).format('YYYY-MM-DD'),
              dateTo: moment(dateRange[1]).format('YYYY-MM-DD'),
            }
          : {}),
      };

      const onboardingStatisticsResponse = await jopaccApi.getOnboardingStatistics(qs.stringify(queryObj));
      const data = onboardingStatisticsResponse?.data?.data ?? statisticsInitialState;
      setStatistics(useFormatStatistics(data));

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || props.t('error'));
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, []);

  useEffect(() => {
    if ((dateRange[0] && dateRange[1]) || (!dateRange[0] && !dateRange[1])) {
      setLoading(true);
      fetchData();
      setLoading(false);
    }
  }, [dateRange]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('Statistics')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="container-fluid mb-4">
              <div className="d-flex mb-3">
                <h4 className="font-size-24">{props.t('Onboarding Statistics')}</h4>
              </div>

              <Row>
                <div className="d-flex mb-3 mt-4 justify-content-end align-items-end">
                  <Col md="4" xl="3">
                    <label>{props.t('Onboarding Period')}</label>
                    <DatePicker
                      selectsRange={true}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                      onChange={setDateRange}
                      isClearable={true}
                      clearButtonClassName="datepicker-clear-button"
                      wrapperClassName="datepicker-wrapper"
                    />
                  </Col>
                </div>
              </Row>
              <Row>
                <Col xl="12">
                  <StatisticsCards data={statistics} handleClick={setDisplayed} displayed={displayed} />
                  <StatisticDetails data={useFormatStatistics(statistics[displayed].data.details)} />
                </Col>
              </Row>
            </div>
          </>
        )}
      </Container>
      <VerificationStatistics />
    </div>
  );
};

export default withRouter(withTranslation()(Statistics));
