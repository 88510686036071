import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import userAvatarPlaceholder from '../../../../shared/assets/images/profile_image_placeholder.png';
import { ENTITY_TYPE } from '../../../constants/domain';

const ProfileMenu = props => {
  const user = useSelector(state => state.app.profile);
  const [menu, setMenu] = useState(false);
  const settings = useSelector(state => state.app.settings);

  const lang = localStorage.getItem('i18nextLng');
  const username =
    lang === 'ar' && user?.data?.arFName
      ? `${user?.data?.arFName} ${user?.data?.arSName} ${user?.data?.arTName} ${user?.data?.arLName}`
      : `${user?.data?.firstName} ${user?.data?.lastName}`;

  const avatar = () => {
    const type = user?.data?.userRole?.type;
    switch (type) {
      case 'cbj':
        return settings.webAppCbjLogo;
      case 'jopacc':
        return settings.webAppJopaccLogo;
      case 'participant':
        return user?.data?.participant?.logoImg;
      default:
        return user?.data?.selfie ? `data:image/png;base64,${user?.data?.selfie}` : userAvatarPlaceholder;
    }
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <img className="rounded-circle header-profile-user" src={avatar()} alt="Header Avatar" />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to={user?.data?.entityType === ENTITY_TYPE.USER ? '/profile' : '/settings'} className="dropdown-item">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {user?.data?.entityType === ENTITY_TYPE.USER ? props.t('Profile') : props.t('Settings')}
          </Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
