import React from 'react';
import moment from 'moment';

import { CONSENT_STATUS_FILTER } from '../../../../shared/constants/enums';

let translate = t => t;

const options = pagination => ({
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pagination?.paginationParams?.limit || 10,
    totalSize: pagination?.totalCount || 0,
    custom: false,
  },
});

const acceptedTableOptions = (pagination, onAction) => ({
  columns: [
    {
      dataField: 'uid',
      text: translate('ID'),
    },
    {
      dataField: 'firstName',
      text: translate('Name'),
    },
    {
      dataField: 'surname',
      text: translate('Surname'),
    },
    {
      dataField: 'nationality',
      text: translate('Nationality'),
    },
    {
      dataField: 'email',
      text: translate('Email'),
    },
    {
      dataField: 'contactNumber',
      text: translate('Phone'),
    },
    {
      dataField: 'idNumber',
      text: translate('National ID'),
    },
    {
      dataField: 'gender',
      text: translate('Gender'),
    },
    {
      dataField: 'consents[0].dateOfIssue',
      text: translate('Date of Issue'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'consents[0].dateOfOperation',
      text: translate('Date of Approval'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'consents[0].userId',
      text: translate('Actions'),
      formatter: (cell, row) => {
        return (
          <label className="table-action" onClick={() => onAction(cell)}>
            <i className="fa fa-eye" />
            {translate('Details')}
          </label>
        );
      },
    },
  ],
  ...options(pagination),
});

const rejectedTableOptions = pagination => ({
  columns: [
    {
      dataField: 'uid',
      text: translate('ID'),
    },
    {
      dataField: 'idNumber',
      text: translate('National ID'),
    },
    {
      dataField: 'dateOfIssue',
      text: translate('Date of Issue'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'dateOfOperation',
      text: translate('Date of Rejection'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
  ],
  ...options(pagination),
});

const pendingTableOptions = pagination => ({
  columns: [
    {
      dataField: 'uid',
      text: translate('ID'),
    },
    {
      dataField: 'idNumber',
      text: translate('National ID'),
    },
    {
      dataField: 'dateOfIssue',
      text: translate('Date of Issue'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
  ],
  ...options(pagination),
});

const revokedTableOptions = pagination => ({
  columns: [
    {
      dataField: 'uid',
      text: translate('ID'),
    },
    {
      dataField: 'idNumber',
      text: translate('National ID'),
    },
    {
      dataField: 'dateOfIssue',
      text: translate('Date of Issue'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'dateOfOperation',
      text: translate('Date of Revoke'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'lastLogin',
      text: translate('Last Login'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
  ],
  ...options(pagination),
});

const expiredTableOptions = pagination => ({
  columns: [
    {
      dataField: 'uid',
      text: translate('ID'),
    },
    {
      dataField: 'idNumber',
      text: translate('National ID'),
    },
    {
      dataField: 'dateOfIssue',
      text: translate('Date of Issue'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'dateOfExpiration',
      text: translate('Time of Expiration'),
      formatter: (cell, row) => {
        return cell && <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
  ],
  ...options(pagination),
});

const getTableOptions = (status, t) => {
  translate = t;
  switch (status) {
    case CONSENT_STATUS_FILTER.accepted:
      return acceptedTableOptions;
    case CONSENT_STATUS_FILTER.rejected:
      return rejectedTableOptions;
    case CONSENT_STATUS_FILTER.pending:
      return pendingTableOptions;
    case CONSENT_STATUS_FILTER.revoked:
      return revokedTableOptions;
    case CONSENT_STATUS_FILTER.expired:
      return expiredTableOptions;
    default:
      return () => {};
  }
};

export {
  getTableOptions as default,
  pendingTableOptions,
  acceptedTableOptions,
  rejectedTableOptions,
  revokedTableOptions,
};
