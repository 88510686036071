import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import MetisMenu from 'metismenujs';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ENTITY_TYPE } from '../../constants/domain';

const SidebarContent = props => {
  const user = useSelector(state => state.app?.profile?.data);
  const ref = useRef();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    const pathName = props?.location?.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t('Menu')} </li>
            {user?.entityType === ENTITY_TYPE.USER && (
              <li>
                <Link to="/profile">
                  <i className="bx bx-cog" />
                  <span>{props.t('Profile')}</span>
                </Link>
              </li>
            )}
            {user?.entityType === ENTITY_TYPE.USER && (
              <li>
                <Link to="/consents">
                  <i className="bx bx-chat" />
                  <span>{props.t('My Consents')}</span>
                </Link>
              </li>
            )}
            {user?.entityType === ENTITY_TYPE.PARTICIPANT && (
              <>
                <li>
                  <Link to="/participant/customers">
                    <i className="bx bx-chat" />
                    <span>{props.t('Customers')}</span>
                  </Link>
                </li>
                {user.userRole?.name === 'administrator' && (
                  <>
                    <li>
                      <Link to="/participant/users">
                        <i className="bx bx-group" />
                        <span>{props.t('Users')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/billing">
                        <i className="bx bx-dollar" />
                        <span>{props.t('Billing')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/reporting">
                        <i className="bx bx-chart" />
                        <span>{props.t('Reporting')}</span>
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}
            {user?.entityType === ENTITY_TYPE.JOPACC && (
              <>
                <li>
                  <Link to="/jopacc/customers">
                    <i className="bx bx-chat" />
                    <span>{props.t('Customers')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/jopacc/participants">
                    <i className="bx bx-user" />
                    <span>{props.t('Participants')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/jopacc/tiers">
                    <i className="bx bx-receipt" />
                    <span>{props.t('Tiers')}</span>
                  </Link>
                </li>
                {user.userRole?.name === 'administrator' && (
                  <>
                    <li>
                      <Link to="/jopacc/roles">
                        <i className="bx bx-group" />
                        <span>{props.t('Users')}</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/jopacc/billing">
                        <i className="bx bx-dollar" />
                        <span>{props.t('Billing')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/jopacc/reporting">
                        <i className="bx bx-chart" />
                        <span>{props.t('Reporting')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/jopacc/statistics">
                        <i className="bx bx-line-chart" />
                        <span>{props.t('Statistics')}</span>
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}
            {user?.entityType === ENTITY_TYPE.CBJ && (
              <>
                <li>
                  <Link to="/cbj/customers">
                    <i className="bx bx-chat" />
                    <span>{props.t('Customers')}</span>
                  </Link>
                </li>
                {user.userRole?.name === 'administrator' && (
                  <>
                    <li>
                      <Link to="/cbj/roles">
                        <i className="bx bx-chat" />
                        <span>{props.t('Users')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/cbj/participants">
                        <i className="bx bx-user" />
                        <span>{props.t('Participants')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/cbj/tiers">
                        <i className="bx bx-receipt" />
                        <span>{props.t('Tiers')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/cbj/reporting">
                        <i className="bx bx-chart" />
                        <span>{props.t('Reporting')}</span>
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}
            {user?.entityType !== ENTITY_TYPE.USER && (
              <li>
                <Link to="/settings">
                  <i className="bx bx-cog" />
                  <span>{props.t('Settings')}</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
