import Axios, { AxiosResponse } from 'axios';

import { getAsyncItem } from '../../web/helpers/getAsyncItem';
import { IPerConsentBillingResponse, IPeriodBillingResponse } from '../../web/pages/Billing/billing.types';
import {
  JopaccBillingParticipantListResponse,
  JopaccCustomersResponse,
  JopaccParticipantCustomersResponse,
  JopaccParticipantsResponse,
  JopaccReportingParticipantsListResponse,
  JopaccTiersResponse,
} from '../../web/pages/Jopacc/jopacc.types';
import {
  IParticipantOnboardingStatisticsResponse,
  IParticipantStatisticsResponse,
  IParticipantSubscriptionResponse,
  IParticipantTotalBillingResponse,
  VerificationStatisticsResponse,
} from '../../web/pages/Reporting/reporting.types';
import { STORAGE_ITEMS } from '../constants/enums';
import { cleanStorage } from '../services/user';
import { store } from '../store';
import { getStorageItem, setStorageItem } from '../utils/secureStorage';
import { clearSession } from '../store/reducers/app.reducer';
import {
  IAddParticipantUserRequest,
  IAddParticipantUserResponse,
  IAnswerConsentRequest,
  IAppConfigResponse,
  IBigChainConsentsResponse,
  ICheckDocumentRequest,
  ICheckDocumentResponse,
  ICustomerConsentsHistoryResponse,
  IDownloadDocumentResponse,
  IEKYCAuthRequest,
  IEKYCAuthResponse,
  IEKYCForgotPasswordRequest,
  IEKYCForgotPasswordResponse,
  IEKYCUpdateDocumentRequest,
  IEnrollmentRequest,
  IEnrollmentResponse,
  IForgotPasswordRequest,
  IGetCitiesResponse,
  IGetCountriesResponse,
  IJopaccCustomerDetailsResponse,
  IOtherServicesResponse,
  IPaginatedCBJConsentsResponse,
  IPaginatedConsentsResponse,
  IParticipantCustomerDetailsResponse,
  IParticipantUser,
  ISaveAddressRequest,
  ISendEmailByIDRequest,
  ISendEmailByIDResponse,
  ISendEmailRequest,
  ISendEmailResponse,
  ISendForgotPasswordOTPRequest,
  ISendOTPByIDRequest,
  ISendOTPByIDResponse,
  ISendOTPRequest,
  ISendOTPResponse,
  ISignInFaceMatchRequest,
  ISignInOtpRequest,
  ISignInRequest,
  ISignInResponse,
  ISignUpRequest,
  ISingInCheckDocumentRequest,
  IUpdateEmailByIdRequest,
  IUpdateEmailRequest,
  IUpdateEmailResponse,
  IUpdateMainDocumentRequest,
  IUpdatePasswordRequest,
  IUpdatePasswordResponse,
  IUpdatePhoneByIdRequest,
  IUpdatePhoneRequest,
  IUpdatePhoneResponse,
  IUpdateTrustedDeviceRequest,
  IUpdateTrustedDeviceResponse,
  IUploadOtherDocumentRequest,
  IUserAddressResponse,
  IUserProfileResponse,
  IVerifyContactDetailsRequest,
  IVerifyContactDetailsResponse,
  IVerifyEmailOTPRequest,
  IVerifyEmailOTPResponse,
  IVerifyIDNumberForWebResponse,
  IVerifyIDNumberRequest,
  IVerifyOTPRequest,
  IVerifyOTPResponse,
  IVerifyUsernameRequest,
  IVerifyUsernameResponse,
  WebAppConfigResponse,
} from '../types/api';

let baseURL = `${window.location.protocol}//${window.location.hostname}`;

const port = window.location.port;

if (port && !['80', '443'].includes(port)) {
  baseURL = `${baseURL}:${port}`;
}

export const publicAxios = Axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL : baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

publicAxios.interceptors.request.use(
  async config => {
    const webLang = await getAsyncItem('I18N_LANGUAGE');
    config.headers.lang = webLang === 'en' ? 'En' : 'Ar';

    return config;
  },
  error => Promise.reject(error)
);

publicAxios.interceptors.response.use(
  response => response,
  async error => {
    await cleanStorage();

    if (window) {
      if (!window.location.pathname.includes('/login')) {
        store.dispatch(clearSession());
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export const refresh = async () => {
  const authData = getStorageItem(STORAGE_ITEMS.AUTH_DATA);
  const response = await publicAxios.post('/v1/auth/refresh-token', {
    refreshToken: authData.refresh,
  });

  setStorageItem(
    STORAGE_ITEMS.AUTH_DATA,
    JSON.stringify({
      ...authData,
      refresh: response.data.refreshToken,
      authentication: response.data.accessToken,
    })
  );

  return response.data.accessToken as string;
};

const axios = Axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL : baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

axios.interceptors.request.use(
  async config => {
    const authData = getStorageItem(STORAGE_ITEMS.AUTH_DATA);
    const mobileLang = await getStorageItem('language');
    const webLang = await getAsyncItem('I18N_LANGUAGE');

    // const participantApiKey = (await getStorageItem(STORAGE_ITEMS.PARTICIPANT_KEY)) as string;
    if (mobileLang) {
      config.headers.lang = mobileLang === 'ar_JO' ? 'Ar' : 'En';
    } else {
      config.headers.lang = webLang === 'en' ? 'En' : 'Ar';
    }

    // add authorization token on first request
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${authData.authentication}`;
      return config;
    }

    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  async error => {
    const prevRequest = error.config;
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      if (!prevRequest.sent) {
        const newAccessToken = await refresh();
        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        prevRequest.sent = true;
        return axios.request(prevRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const api = {
  onBoard: (payload: ISignUpRequest): Promise<AxiosResponse<ISignInResponse>> =>
    axios.post('/v1/auth/onboard', payload),
  singIn: (payload: ISignInRequest): Promise<AxiosResponse<ISignInResponse>> =>
    publicAxios.post('/v1/auth/sign-in', payload),
  singInFaceMatch: (payload: ISignInFaceMatchRequest): Promise<AxiosResponse<ISignInResponse>> =>
    axios.post('/v1/kyc/sign-in/faceMatch/ekyc', payload),
  verifyIDNumberForWeb: (payload: IVerifyIDNumberRequest): Promise<AxiosResponse<IVerifyIDNumberForWebResponse>> =>
    axios.post('/v1/otp-jopacc/verify-id-number-otp', payload),
  signInOtp: (payload: ISignInOtpRequest): Promise<AxiosResponse<ISignInResponse>> =>
    axios.post('/v1/otp-jopacc/otp/sign-in', payload),
  updateTrustedDevice: (payload: IUpdateTrustedDeviceRequest): Promise<AxiosResponse<IUpdateTrustedDeviceResponse>> =>
    axios.put('/v1/auth/update-trusted-device', payload),
  checkDocument: (payload: ICheckDocumentRequest): Promise<AxiosResponse<ICheckDocumentResponse>> =>
    axios.post('/v1/kyc/onboard/doc', payload),
  singInCheckDocument: (payload: ISingInCheckDocumentRequest): Promise<AxiosResponse<ICheckDocumentResponse>> =>
    axios.post('/v1/kyc/sign-in/doc', payload),
  ekycEnroll: (payload: IEnrollmentRequest): Promise<AxiosResponse<IEnrollmentResponse>> =>
    axios.post('/v1/kyc/onboard/ekyc', payload),
  ekycAuth: (payload: IEKYCAuthRequest): Promise<AxiosResponse<IEKYCAuthResponse>> =>
    axios.post('/v1/kyc/faceMatch/ekyc', payload),
  ekycForgotPassword: (payload: IEKYCForgotPasswordRequest): Promise<AxiosResponse<IEKYCForgotPasswordResponse>> =>
    axios.post('/v1/kyc/forget/faceMatch/ekyc', payload),
  settings: (): Promise<AxiosResponse<WebAppConfigResponse>> => axios.get('/v1/system-config/web-app-configs'),
  profile: (): Promise<AxiosResponse<IUserProfileResponse>> => axios.get('/v1/users/profile?filterMeta=true'),
  // Commented bigchain to test chronicle
  // getConsents: (userId: number): Promise<AxiosResponse<{ data: IBigChainConsentsResponse }>> =>
  //   axios.get(`/v1/bigchain-consent?customerUid=${userId}`),
  getConsents: (userId: number): Promise<AxiosResponse<{ data: IBigChainConsentsResponse }>> =>
    axios.get(`/v1/chronicle-consent?customerUid=${userId}`),
  // getConsents: (): Promise<AxiosResponse<IConsentsResponse[]>> => axios.get('/v1/consent?includeParticipants=true'),
  getPaginatedConsents: (query: string): Promise<AxiosResponse<IPaginatedConsentsResponse>> =>
    axios.get(`/v1/users/participant/customers?${query}`),
  getPaginatedCBJConsents: (query: string): Promise<AxiosResponse<IPaginatedCBJConsentsResponse>> =>
    axios.get(`/v1/users/cbj/customers?${query}`),
  // Commented bigchain to test chronicle
  // answerConsent: (id: string, payload: IAnswerConsentRequest): Promise<AxiosResponse<any>> =>
  //   axios.patch(`/v1/bigchain-consent/${id}`, payload),
  answerConsent: (id: string, payload: IAnswerConsentRequest): Promise<AxiosResponse<any>> =>
    axios.patch(`/v1/chronicle-consent/${id}`, payload),
  logout: (token: string): Promise<AxiosResponse> => axios.delete(`/v1/auth/logout/${token}`),
  deleteUser: (id: number): Promise<AxiosResponse> => axios.delete(`/v1/users/user/${id}`),
  sendOTP: (payload: ISendOTPRequest): Promise<AxiosResponse<ISendOTPResponse>> =>
    axios.post('/v1/otp-jopacc/send-sms', payload),
  sendSmsOTPById: (payload: ISendOTPByIDRequest): Promise<AxiosResponse<ISendOTPByIDResponse>> =>
    axios.post('/v1/otp-jopacc/send-sms-by-id-number', payload),
  sendForgotPasswordOTP: (payload: ISendForgotPasswordOTPRequest): Promise<AxiosResponse<ISendOTPResponse>> =>
    axios.post('/v1/otp-jopacc/forgotPasswordOtp', payload),
  verifyOTP: (payload: IVerifyOTPRequest): Promise<AxiosResponse<IVerifyOTPResponse>> =>
    axios.post('/v1/otp-jopacc/verify-sms', payload),
  updatePhoneNumber: (payload: IUpdatePhoneRequest): Promise<AxiosResponse<IUpdatePhoneResponse>> =>
    axios.post('/v1/users/update/phone', payload),
  updatePhoneNumberById: (payload: IUpdatePhoneByIdRequest): Promise<AxiosResponse<IUpdatePhoneResponse>> =>
    axios.post('/v1/users/update-by-id-number/phone', payload),
  updateEmailById: (payload: IUpdateEmailByIdRequest): Promise<AxiosResponse<IUpdateEmailResponse>> =>
    axios.post('/v1/users/update-by-id-number/email', payload),
  changePassword: (payload: IUpdatePasswordRequest): Promise<AxiosResponse<IUpdatePasswordResponse>> =>
    axios.put('/v1/auth/changePassword', payload),
  forgotPassword: (payload: IForgotPasswordRequest): Promise<AxiosResponse<IUpdatePasswordResponse>> =>
    axios.put('/v1/auth/forgotPassword', payload),
  sendEmailOTP: (payload: ISendEmailRequest): Promise<AxiosResponse<ISendEmailResponse>> =>
    axios.post('/v1/otp-jopacc/send-email', payload),
  sendEmailOTPById: (payload: ISendEmailByIDRequest): Promise<AxiosResponse<ISendEmailByIDResponse>> =>
    axios.post('/v1/otp-jopacc/send-email-by-id-number', payload),
  verifyEmailOTP: (payload: IVerifyEmailOTPRequest): Promise<AxiosResponse<IVerifyEmailOTPResponse>> =>
    axios.post('/v1/otp-jopacc/verify-email', payload),
  updateEmail: (payload: IUpdateEmailRequest): Promise<AxiosResponse<IUpdateEmailResponse>> =>
    axios.post('/v1/users/update/email', payload),
  verifyUsername: (payload: IVerifyUsernameRequest): Promise<AxiosResponse<IVerifyUsernameResponse>> =>
    axios.post('/v1/users/verify/username', payload),
  getParticipantKey: (): Promise<AxiosResponse<string>> => axios.get('/v1/participants/dummy/participantApiKey'),
  checkUpdatedDocument: (payload: IEKYCUpdateDocumentRequest): Promise<AxiosResponse<ICheckDocumentResponse>> =>
    axios.post('/v1/kyc/document/update/doc', payload),
  ekycUpdatedDocumentFaceMatch: (payload: IEnrollmentRequest): Promise<AxiosResponse<IEnrollmentResponse>> =>
    axios.post('/v1/kyc/document/update/faceMatch', payload),
  updateMainDocument: (payload: IUpdateMainDocumentRequest): Promise<AxiosResponse<{ data: string }>> =>
    axios.post('/v1/users/update/doc/primary', payload),
  uploadOtherDocument: (payload: IUploadOtherDocumentRequest): Promise<AxiosResponse<{ data: string }>> =>
    axios.post('/v1/kyc/onboard/doc/other', payload),
  deleteOtherDocument: (id: string): Promise<AxiosResponse> => axios.delete(`/v1/users/doc/foreign/${id}`),
  downloadOtherDocument: (id: string): Promise<AxiosResponse<IDownloadDocumentResponse>> =>
    axios.get(`/v1/users/doc/foreign/${id}`),
  getParticipantTierInfo: (participantId: string): Promise<AxiosResponse> =>
    axios.get(`/v1/participants/${participantId}/tier`),
  verifyContactDetails: (
    payload: IVerifyContactDetailsRequest
  ): Promise<AxiosResponse<IVerifyContactDetailsResponse>> => axios.post('/v1/users/verify/contact-details', payload),
  verifyContactDetailsOnboarding: (
    payload: IVerifyContactDetailsRequest
  ): Promise<AxiosResponse<IVerifyContactDetailsResponse>> =>
    axios.post('/v1/users/onboard/verify-contact-details', payload),
  getCountries: (): Promise<AxiosResponse<IGetCountriesResponse>> => axios.get('/v1/address/countries-lookup'),
  getCities: (countryId: string): Promise<AxiosResponse<IGetCitiesResponse>> =>
    axios.get(`/v1/address/countries/${countryId}/cities`),
  saveAddress: (payload: ISaveAddressRequest): Promise<AxiosResponse<any>> =>
    axios.post('/v1/address/upsert-self-address', payload),
  getAddress: (): Promise<AxiosResponse<IUserAddressResponse>> => axios.get('/v1/address/get-self-address'),
  getOtherServices: (): Promise<AxiosResponse<IOtherServicesResponse>> =>
    axios.get('/v1/system-config/other-service-url'),
  getAppConfig: (): Promise<AxiosResponse<IAppConfigResponse>> => axios.get('/v1/system-config/app-configs'),
};

export const participantAPI = {
  addUser: (payload: IAddParticipantUserRequest): Promise<AxiosResponse<IAddParticipantUserResponse>> =>
    axios.post('/v1/participants/users', payload),
  updateUser: (userId: number, data: IAddParticipantUserRequest): Promise<AxiosResponse> =>
    axios.patch(`/v1/participants/users/${userId}`, data),
  deleteUser: (userId: number): Promise<AxiosResponse> => axios.delete(`/v1/participants/users/${userId}`),
  getAllUsers: (): Promise<AxiosResponse<{ data: IParticipantUser[] }>> => axios.get('/v1/participants/users'),
  getCustomerDetails: (customerUserId: number): Promise<AxiosResponse<IParticipantCustomerDetailsResponse>> =>
    axios.get(`/v1/users/participant/customers/${customerUserId}`),
  getAllOnboardingStatistics: (query?: string): Promise<AxiosResponse<IParticipantOnboardingStatisticsResponse>> =>
    axios.get(`/v1/participants/on-boarding-statistics?${query}`),
};

export const billingAPI = {
  getTransactions: (searchQuery: string): Promise<AxiosResponse<IPeriodBillingResponse>> =>
    axios.get(`/v1/transactions?${searchQuery}`),
  getPerConsentTransactions: (searchQuery: string): Promise<AxiosResponse<IPerConsentBillingResponse>> =>
    axios.get(`/v1/transactions/per-consent?${searchQuery}`),
};

export const reportingApi = {
  getStatistics: (query: string): Promise<AxiosResponse<IParticipantStatisticsResponse>> =>
    axios.get(`/v1/participants/statistics?${query}`),
  getTotalBillingAndTier: (participantId: string): Promise<AxiosResponse<IParticipantTotalBillingResponse>> =>
    axios.get(`/v1/participants/billing?participantId=${participantId}`),
  getSubscription: (participantId: string): Promise<AxiosResponse<IParticipantSubscriptionResponse>> =>
    axios.get(`/v1/participants/subscriptions?participantId=${participantId}`),
  getParticipantSubscription: (participantId: string): Promise<AxiosResponse<IParticipantSubscriptionResponse>> =>
    axios.get(`/v1/participants/${participantId}`),
};

export const jopaccApi = {
  getUserConsents: (query: string): Promise<AxiosResponse<JopaccCustomersResponse>> =>
    axios.get(`/v1/users/jopacc/customers?${query}`),
  getParticipantsOnboardingStatistics: (
    participantId: string,
    query?: string
  ): Promise<AxiosResponse<IParticipantOnboardingStatisticsResponse>> =>
    axios.get(`/v1/jopacc/on-boarding-statistics/${participantId}?${query}`),
  getOnboardingStatistics: (query?: string): Promise<AxiosResponse<IParticipantOnboardingStatisticsResponse>> =>
    axios.get(`/v1/jopacc/on-boarding-statistics?${query}`),
  getVerificationStatistics: (query?: string): Promise<AxiosResponse<VerificationStatisticsResponse>> =>
    axios.get(`/v1/scores-logger/statistics?${query}`),

  getParticipant: (tierId?: string): Promise<AxiosResponse<JopaccParticipantsResponse>> =>
    axios.get(`/v1/participants${tierId ? `?tierId=${tierId}` : ''}`),
  getParticipantUserConsents: (
    participantId: string,
    query: string
  ): Promise<AxiosResponse<JopaccParticipantCustomersResponse>> =>
    axios.get(`/v1/users/jopacc/participant/${participantId}/customers?${query}`),
  getJopaccReportingParticipantsList: (): Promise<AxiosResponse<JopaccReportingParticipantsListResponse>> =>
    axios.get('v1/participants/jopacc/participant-consents'),
  getTiers: (): Promise<AxiosResponse<JopaccTiersResponse>> => axios.get('v1/tier/jopacc'),
  getJopaccBillingParticipantList: (): Promise<AxiosResponse<JopaccBillingParticipantListResponse>> =>
    axios.get('/v1/participants/jopacc/billing'),
  getJopaccCustomerDetails: (customerUserId: number): Promise<AxiosResponse<IJopaccCustomerDetailsResponse>> =>
    axios.get(`/v1/users/jopacc/customers/${customerUserId}`),
  getJopaccUsers: (): Promise<AxiosResponse<IParticipantUser[]>> => axios.get('/v1/jopacc/users'),
  getCustomerConsentsHistory: (
    customerUserId: number,
    query: string
  ): Promise<AxiosResponse<ICustomerConsentsHistoryResponse>> =>
    axios.get(`/v1/users/jopacc/customers/${customerUserId}/consents?${query}`),
  addUser: (payload: IAddParticipantUserRequest): Promise<AxiosResponse<IAddParticipantUserResponse>> =>
    axios.post('/v1/jopacc/users', payload),
  updateUser: (userId: number, data: IAddParticipantUserRequest): Promise<AxiosResponse> =>
    axios.patch(`/v1/jopacc/users/${userId}`, data),
  deleteUser: (userId: number): Promise<AxiosResponse> => axios.delete(`/v1/jopacc/users/${userId}`),
};

export const cbjApi = {
  getCBJCustomerDetails: (customerUserId: number): Promise<AxiosResponse<IJopaccCustomerDetailsResponse>> =>
    axios.get(`/v1/users/cbj/customers/${customerUserId}`),
  getCBJUsers: (): Promise<AxiosResponse<IParticipantUser[]>> => axios.get('/v1/cbj/users'),
  addUser: (payload: IAddParticipantUserRequest): Promise<AxiosResponse<IAddParticipantUserResponse>> =>
    axios.post('/v1/cbj/users', payload),
  updateUser: (userId: number, data: IAddParticipantUserRequest): Promise<AxiosResponse> =>
    axios.patch(`/v1/cbj/users/${userId}`, data),
  deleteUser: (userId: number): Promise<AxiosResponse> => axios.delete(`/v1/cbj/users/${userId}`),
  getParticipantUserConsents: (
    participantId: string,
    query: string
  ): Promise<AxiosResponse<JopaccParticipantCustomersResponse>> =>
    axios.get(`/v1/users/cbj/participant/${participantId}/customers?${query}`),
  getCBJReportingParticipantsList: (): Promise<AxiosResponse<JopaccReportingParticipantsListResponse>> =>
    axios.get('v1/participants/cbj/participant-consents'),
  getTiers: (): Promise<AxiosResponse<JopaccTiersResponse>> => axios.get('v1/tier/cbj'),
};
