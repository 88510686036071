export const verificationStatisticsInitialState = {
  FACE_LIVENESS: {
    FACE_LIVE_SCORE_LIVE: {
      LIVE: {},
      SPOOF: {},
      TOO_BLURRY: {},
      UNABLE_TO_CALCULATE_LIVENESS: {},
    },
  },
  FACE_MATCHING: {
    FACE_MATCH_SCORE_JORDAN: {
      MATCHED: {},
      NOT_MATCHED: {},
    },
    FACE_MATCH_SCORE_INTERNATIONAL: {
      MATCHED: {},
      NOT_MATCHED: {},
    },
    FACE_MATCH_SCORE_TWINS: {
      MATCHED: {},
      NOT_MATCHED: {},
    },
    FACE_MATCH_SCORE_SIGN_IN: {
      MATCHED: {},
      NOT_MATCHED: {},
    },
  },
  DOCUMENT_LIVENESS: {
    DOC_LIVE_SCORE_GENUINE: {
      GENUINE: {},
      FAKE: {},
    },
  },
};
