import { api } from '../../api';
import { IPaginatedCBJConsentsResponse, IPaginatedConsentsResponse } from '../../types/api';

export const getParticipantConsents = async (filter?: string): Promise<IPaginatedConsentsResponse> => {
  const { data } = await api.getPaginatedConsents(filter);
  return data;
};

export const getCBJConsents = async (filter?: string): Promise<IPaginatedCBJConsentsResponse> => {
  const { data } = await api.getPaginatedCBJConsents(filter);
  return data;
};
