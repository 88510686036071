import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { StyledCard } from './StyledCard';

const titles = {
  FACE_MATCHING: {
    label: 'Face matching',
    visible: true,
  },
  FACE_LIVENESS: {
    label: 'Face liveness',
    visible: true,
  },
  DOCUMENT_LIVENESS: {
    label: 'Document liveness',
    visible: true,
  },
  security: {
    label: 'Security',
    visible: true,
  },
};

const VerificationCards = ({ t, data, handleClick, displayed }) => {
  return (
    <Row className="mb-4">
      {data.map(
        (report, key) =>
          titles[report.title].visible && (
            <Col lg="4" md="4" xl="2" key={'_col_' + key} className="mb-2">
              <StyledCard
                title={t(titles[report.title].label)}
                value={report.all}
                handleClick={() => {
                  handleClick(key);
                }}
                selected={key === displayed}
              />
            </Col>
          )
      )}
    </Row>
  );
};

export default withTranslation()(VerificationCards);
