import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Table } from 'reactstrap';

const VerificationDetails = ({ data, t }) => {
  const formatTableData = data => {
    let formattedData = {
      rows: [],
      heads: [],
    };
    let headSet = new Set();

    for (let [th, value] of Object.entries(data)) {
      let tds = [];
      for (let [key, td] of Object.entries(value)) {
        tds.push(td);
        headSet.add(key);
      }
      formattedData.heads = Array.from(headSet);
      formattedData.rows.push({ th, tds });
    }

    return formattedData;
  };

  const createTablesData = data => {
    let tables = [];
    for (let [key, value] of Object.entries(data)) {
      tables.push({
        title: key,
        data: value,
      });
    }

    return tables;
  };

  const tables = createTablesData(data).map(table => ({ title: table.title, data: formatTableData(table.data) }));

  return (
    <>
      {tables.map(table => (
        <div key={table.title}>
          <div className="d-flex mb-3 mt-5">
            <h5 className="font-size-16">{t(table.title)}</h5>
          </div>
          <Col xl="12" md="12">
            <div className="container-fluid bg-white pb-1">
              <Table striped responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"></th>
                    {table.data.heads.map(head => (
                      <th scope="col" key={head}>
                        {t(head)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {table.data.rows.map(row => (
                    <tr key={row.th}>
                      <th scope="row" width={150}>
                        {t(row.th)}
                      </th>
                      {row.tds.map((td, index) => (
                        <td key={td + index} width={150}>
                          {td}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </div>
      ))}
    </>
  );
};

export default withTranslation()(VerificationDetails);
