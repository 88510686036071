import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { env } from '../../../env';
import { api } from '../../../shared/api';
import logo from '../../../shared/assets/images/i_logo.png';
import { STORAGE_ITEMS } from '../../../shared/constants/enums';
import { getUserProfile } from '../../../shared/services/user';
import { setProfile } from '../../../shared/store/reducers/app.reducer';
import { setStorageItem } from '../../../shared/utils/secureStorage';
import LanguageDropdown from '../../components/CommonForBoth/TopbarDropdown/LanguageDropdown';
import { ENTITY_TYPE } from '../../constants/domain';
import './styles.css';

const Login = ({ history, t }) => {
  const lang = localStorage.getItem('i18nextLng');
  const [Counter, setCounter] = useState(58);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  const [otp, setOtp] = useState('');
  const [OTPRequested, setOTPRequested] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nationalID, setNationalID] = useState('');
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nationalId: '',
    },
    validationSchema: Yup.object({
      nationalId: Yup.string().required(t('Please Enter Your National ID')),
    }),
    onSubmit: async ({ nationalId }) => {
      try {
        if (!token) {
          toast.error(t('Please verify your ReCaptcha'));
          setError(t('Suspicious activity detected. Please try again later.'));
          return;
        }

        setLoading(true);
        setNationalID(nationalId);
        const response = await api.verifyIDNumberForWeb({ idNumber: nationalId });
        setLoading(false);

        if (!response.data?.contactNumber) {
          toast.error(t('Invalid Credentials'));
          setError(t('Invalid Credentials'));
          return;
        }

        setPhoneNumber(response.data.contactNumber);
        setOTPRequested(true);
        setError(null);
        counterDown();
      } catch (error) {
        setError(
          [400, 401, 404].includes(error?.response?.status)
            ? t('Invalid Credentials')
            : t('Something went wrong. Please try again later.')
        );
      }
      setLoading(false);
    },
  });

  const counterDown = async () => {
    let count = 58;
    const timer = setInterval(() => {
      count--;
      setCounter(count);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setOTPRequested(false);
      setCounter(58);
    }, 58000);
  };

  const onOTPFormSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.signInOtp({ otp, contactNumber: phoneNumber, idNumber: nationalID });
      setStorageItem(STORAGE_ITEMS.AUTH_DATA, JSON.stringify(response.data));

      const profile = await getUserProfile();
      const type = profile?.data?.entityType;

      if (type !== ENTITY_TYPE.USER) {
        setError(t('Suspicious activity detected. User not authorized to access this portal.'));
        setLoading(false);
        setPhoneNumber('');
        setOTPRequested(false);
        setOtp('');
        return;
      }

      dispatch(setProfile(profile));
      history.push('/profile');
    } catch (error) {
      setLoading(false);
      setError(
        [400, 401, 404].includes(error?.response?.status)
          ? t('Invalid Credentials')
          : t('Something went wrong. Please try again later.')
      );
    }
  }, [OTPRequested, otp, phoneNumber, nationalID]);

  const onOTPKeyUp = e => {
    if (e.keyCode === 13 && otp.length === 6) {
      onOTPFormSubmit();
    }
  };

  const onReCaptchaChange = useCallback(token => {
    setToken(token);
  }, []);

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={env.REACT_APP_RECAPTCHA_KEY?.toString() ?? '6LcpwxcoAAAAAI7lpNxirdGUjrOQ7PNXJZi0-Jm4'}
        language={lang || 'en'}
        container={{
          element: '',
          parameters: {
            errorCallback: () => {
              toast.error('Suspicious activity detected. Please try again later.');
              setTimeout(() => {
                window.location.href = '/logout';
              }, 3000);
            },
          },
        }}>
        <MetaTags>
          <title>{t('Login')} | Jopacc</title>
        </MetaTags>
        <div className="ms-4 mt-4">
          <img src={logo} height="100" className="logo" />
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="py-0">
                    <div className="p-2">
                      {phoneNumber ? (
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault();
                            onOTPFormSubmit();
                            return false;
                          }}>
                          <div className="mb-4 mt-4">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <Label className="form-label">{t('OTP')}</Label>
                              <button
                                className="btn btn-link btn-block small"
                                type="button"
                                onClick={() => validation.handleSubmit()}
                                disabled={loading || OTPRequested}>
                                {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />}
                                {`${t('Resend OTP')} ${OTPRequested ? Counter : ''}`}
                              </button>
                            </div>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              containerStyle={{
                                direction: 'ltr',
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: 4,
                              }}
                              inputStyle={{ width: 60, height: 40, borderRadius: 4, border: '1px solid #ced4da' }}
                              renderInput={props => <input {...props} onKeyUp={onOTPKeyUp} />}
                            />
                            <Label className="text-muted small mt-2">{`${t(
                              'Please enter the 6 digit code sent to'
                            )} ${phoneNumber.substring(1, 5)}XXXX${phoneNumber.substring(
                              8,
                              phoneNumber.length - 1
                            )}`}</Label>
                          </div>

                          {error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="mt-4 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={loading || otp.length < 6}>
                              {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />}
                              {t('Verify')}
                            </button>
                          </div>
                        </Form>
                      ) : (
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                          <div className="mb-4 mt-4">
                            <Label className="form-label">{t('National ID')}</Label>
                            <Input
                              name="nationalId"
                              className="form-control"
                              placeholder={t('Enter National ID')}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.nationalId || ''}
                              invalid={validation.touched.nationalId && validation.errors.nationalId ? true : false}
                            />
                            {validation.touched.nationalId && validation.errors.nationalId ? (
                              <FormFeedback type="invalid">{validation.errors.nationalId}</FormFeedback>
                            ) : null}
                            <Label className="text-muted small mt-2">
                              {t('An OTP will be sent to your mobile number.')}
                            </Label>
                          </div>

                          {error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="mt-4 d-grid">
                            <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
                              {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />}
                              {t('Submit')}
                            </button>
                          </div>
                        </Form>
                      )}

                      <div className="mb-3">
                        <GoogleReCaptcha onVerify={onReCaptchaChange} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p>© {new Date().getFullYear()} Jopacc</p>
                </div>
              </Col>
            </Row>
            <div className="language-btn">
              <LanguageDropdown inline />
            </div>
          </Container>
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
};
