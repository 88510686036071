import React from 'react';
import moment from 'moment';

import { getAge } from '../../../helpers/functions';
import { ENTITY_TYPE } from '../../../constants/domain';

let translate = t => t;

const DateOfOperationColumnName = {
  pending: 'Date of Operation',
  accepted: 'Date of Approval',
  rejected: 'Date of Rejection',
  revoked: 'Date of Revoke',
  all: 'Date Of Operation',
  expired: 'Date of Expiration',
};

const jopaccColumns = () => [
  {
    dataField: 'username',
    text: translate('Username'),
    sort: false,
  },
  {
    dataField: 'nationality',
    text: translate('Nationality'),
    sort: false,
  },
  {
    dataField: 'gender',
    text: translate('Gender'),
    sort: false,
  },
  {
    dataField: 'birthDate',
    text: translate('Age'),
    formatter: (cell, row) => {
      return getAge(cell);
    },
    sort: false,
  },
];

// TODO: set the correct fields once returned from API
const cbjColumns = () => [
  {
    dataField: 'username',
    text: translate('Username'),
    sort: false,
  },
];

export const getDataTableOptions = (options, pageSize, onAction, entityType, filter, t) => {
  translate = t;

  return {
    columns: [
      {
        dataField: 'id',
        text: translate('ID'),
        sort: false,
      },
      ...(entityType === ENTITY_TYPE.JOPACC ? jopaccColumns() : cbjColumns()),
      {
        dataField: 'dateOfIssue',
        text: translate('Date of Onboarding'),
        sort: false,
        formatter: (cell, row) => {
          return <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
        },
      },
      ...(filter !== 'pending' && filter !== 'expired'
        ? [
            {
              dataField: 'dateOfOperation',
              text: translate(DateOfOperationColumnName[filter]),
              sort: false,
              formatter: (cell, row) => {
                return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '';
              },
            },
          ]
        : []),
      ...(filter === 'expired'
        ? [
            {
              dataField: 'dateOfExpiration',
              text: translate(DateOfOperationColumnName[filter]),
              sort: false,
              formatter: (cell, row) => {
                return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '';
              },
            },
          ]
        : []),
      // {
      //   dataField: 'id',
      //   text: 'Actions',
      //   formatter: (cell, row) => {
      //     return (
      //       <label className="table-action" onClick={() => onAction('logs', cell)}>
      //         <i className="fa fa-history" />
      //         Logs
      //       </label>
      //     );
      //   },
      // },
    ],
    defaultSorted: [
      {
        dataField: 'id',
        order: 'asc',
      },
    ],
    pageOptions: {
      sizePerPage: pageSize || 10,
      totalSize: options?.totalCount || 0,
      custom: false,
    },
    selectRow: {
      mode: 'checkbox',
      hideSelectColumn: true,
    },
  };
};
