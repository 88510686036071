import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { toast } from 'react-toastify';
import { Container, Spinner } from 'reactstrap';
import qs from 'qs';

import { billingAPI } from '../../../../shared/api';
import ServerDataTable from '../../../components/ServerDataTable';

import { getDataTableOptions } from './datatable-options';
import BillingSearchBar from './BillingSearchBar';

const PerConsentBilling = props => {
  const [loading, setLoading] = useState(false);
  const [queryOptions, setQueryOptions] = useState({
    limit: 10,
    page: 1,
    sortOrder: 'asc',
    sortField: 'id',
    sum: true,
  });
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalAmount, setTotalAmount] = useState('');
  const [dataTableOptions, setDataTableOptions] = useState(getDataTableOptions(0, pageSize, props.t));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const queryObj = Object.keys(queryOptions).reduce((p, c) => {
          if (queryOptions[c]) {
            p = { ...p, [c]: queryOptions[c] };
          }
          return p;
        }, {});

        const response = await billingAPI.getPerConsentTransactions(qs.stringify(queryObj));
        setData(response.data.data.paginatedResult);
        setTotalAmount(response.data.data.totalAmount);
        setDataTableOptions(getDataTableOptions(response.data.options, pageSize, props.t));
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || 'Something went wrong');
      }
      setLoading(false);
    };

    fetchData();
  }, [queryOptions, pageSize]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage: limit, sortField, sortOrder } = newState;

    setPageSize(limit);
    setQueryOptions({
      ...queryOptions,
      page,
      limit,
      sortOrder,
      sortField: sortField ?? 'id',
    });
  };

  const onFilterApplied = filter => {
    setQueryOptions({
      ...queryOptions,
      ...filter,
    });
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('Billing')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        <>
          <div className="d-flex mx-3 mb-3">
            <h4 className="font-size-24">
              {props.t('Billing')} {props.participantName ? `(${props.participantName})` : ''}
              {totalAmount ? ` - ${props.t('Total amount is')} ${totalAmount} ${props.t('JDs')}` : ''}
              {loading && <i className="bx bx-loader bx-spin align-middle me-2" />}
            </h4>
          </div>

          <ServerDataTable
            onTableChange={onTableChange}
            data={data}
            options={dataTableOptions}
            withoutSearch
            headerComponent={<BillingSearchBar loading={loading} onApply={onFilterApplied} />}
            keyField="uid"
          />
        </>
      </Container>
    </div>
  );
};

export default withTranslation()(PerConsentBilling);
