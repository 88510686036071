import React, { useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { CONSENT_FILTER_TO_DATE_TITLE } from '../../../../shared/constants/lookups';

const SearchBar = ({ t, loading, onApply, statusFilter }) => {
  const [id, setId] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [dateRange, setDateRange] = useState({
    dateOfIssue: [null, null],
    dateOfOperation: [null, null],
  });

  const onSubmit = () => {
    onApply({
      dateOfIssueFrom: dateRange.dateOfIssue[0] ? moment(dateRange.dateOfIssue[0]).format('YYYY-MM-DD') : null,
      dateOfIssueTo: dateRange.dateOfIssue[1] ? moment(dateRange.dateOfIssue[1]).format('YYYY-MM-DD') : null,
      dateOfOperationFrom: dateRange.dateOfOperation[0]
        ? moment(dateRange.dateOfOperation[0]).format('YYYY-MM-DD')
        : null,
      dateOfOperationTo: dateRange.dateOfOperation[1]
        ? moment(dateRange.dateOfOperation[1]).format('YYYY-MM-DD')
        : null,
      idNumber,
      id,
    });
  };

  return (
    <Row className="mb-4">
      <Col lg="1" md="6">
        <label>{t('ID')}</label>
        <Input value={id} onChange={e => setId(e.target.value)} />
      </Col>
      <Col lg="2" md="6">
        <label>{t('National ID')}</label>
        <Input value={idNumber} onChange={e => setIdNumber(e.target.value)} />
      </Col>
      <Col lg="4" md="6">
        <label>{t('Date of Issue')}</label>
        <DatePicker
          selectsRange={true}
          startDate={dateRange.dateOfIssue[0]}
          endDate={dateRange.dateOfIssue[1]}
          onChange={update => {
            setDateRange({
              ...dateRange,
              dateOfIssue: update,
            });
          }}
          isClearable={true}
          clearButtonClassName="datepicker-clear-button"
          wrapperClassName="datepicker-wrapper"
        />
      </Col>
      <Col lg="4" md="6">
        <label>{t(CONSENT_FILTER_TO_DATE_TITLE[statusFilter] ?? 'Date of Operation')}</label>
        <DatePicker
          selectsRange={true}
          startDate={dateRange.dateOfOperation[0]}
          endDate={dateRange.dateOfOperation[1]}
          onChange={update => {
            setDateRange({
              ...dateRange,
              dateOfOperation: update,
            });
          }}
          isClearable={true}
          clearButtonClassName="datepicker-clear-button"
          wrapperClassName="datepicker-wrapper"
        />
      </Col>

      <Col lg="1" md="12" className="d-flex apply-btn-container">
        <Button disabled={loading} onClick={onSubmit} type="button" className="flex-fill" color="primary">
          {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />} {t('Apply')}
        </Button>
      </Col>
    </Row>
  );
};

export default withTranslation()(SearchBar);
