import React from 'react';
import { capitalize } from 'lodash';
import moment from 'moment';

import { CONSENT_STATUS_FILTER } from '../../../../shared/constants/enums';

let translate = label => label;

const sharedColumns = onParticipantQueryClicked => [
  {
    dataField: 'participant.name',
    text: translate('Organization'),
    sort: true,
    formatter: (cell, row) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        {row.participant.logoImg && row.participant.logoImg !== 'string' && (
          <img
            style={{
              objectFit: 'contain',
              width: 32,
              height: 32,
              borderRadius: 16,
            }}
            src={row.participant.logoImg}
            alt=""
          />
        )}
        <i
          className="fas fa-info-circle question-mark-icon"
          onClick={() => onParticipantQueryClicked(row.participantId)}
        />
        {cell}
      </div>
    ),
  },
];

const options = rowCount => ({
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: 10,
    totalSize: rowCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});

const allConsentsTableOption = (rowCount, onParticipantQueryClicked) => ({
  columns: [
    ...sharedColumns(onParticipantQueryClicked),
    {
      dataField: 'dateOfIssue',
      text: translate('Time of Request'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
    {
      dataField: 'status',
      text: translate('Current Status'),
      sort: true,
      formatter: (cell, row) => {
        return `${translate(capitalize(cell === 'accepted' ? 'approved' : cell))} ${
          cell === 'pending' ? translate('(Use mobile app to approve/reject)') : ''
        }`;
      },
    },
  ],
  ...options(rowCount),
});

const revokedConsentsTableOption = (rowCount, onParticipantQueryClicked) => ({
  columns: [
    ...sharedColumns(onParticipantQueryClicked),
    {
      dataField: 'dateOfIssue',
      text: translate('Time of Request'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
    {
      dataField: 'dateOfOperation',
      text: translate('Time of Revoke'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
  ],
  ...options(rowCount),
});

const expiredConsentsTableOption = (rowCount, onParticipantQueryClicked) => ({
  columns: [
    ...sharedColumns(onParticipantQueryClicked),
    {
      dataField: 'dateOfIssue',
      text: translate('Time of Request'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
    {
      dataField: 'dateOfExpiration',
      text: translate('Time of Expiration'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
  ],
  ...options(rowCount),
});

const pendingConsentsTableOption = (rowCount, onParticipantQueryClicked) => ({
  columns: [
    ...sharedColumns(onParticipantQueryClicked),
    {
      dataField: 'dateOfIssue',
      text: translate('Time of Request'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
  ],
  ...options(rowCount),
});

const rejectedConsentsTableOption = (rowCount, onParticipantQueryClicked) => ({
  columns: [
    ...sharedColumns(onParticipantQueryClicked),
    {
      dataField: 'dateOfIssue',
      text: translate('Time of Request'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
    {
      dataField: 'dateOfOperation',
      text: translate('Time of Rejection'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
  ],
  ...options(rowCount),
});

const acceptedConsentsTableOption = (rowCount, onParticipantQueryClicked) => ({
  columns: [
    ...sharedColumns(onParticipantQueryClicked),
    {
      dataField: 'dateOfIssue',
      text: translate('Time of Request'),
      sort: true,
      formatter: cell => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
    {
      dataField: 'dateOfOperation',
      text: translate('Time of Approval'),
      sort: true,
      formatter: (cell, row) => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '-';
      },
    },
  ],
  ...options(rowCount),
});

export const DataTableOptions = (status, rowCount, onParticipantQueryClicked, t) => {
  translate = t;

  if (status === CONSENT_STATUS_FILTER.all) {
    return allConsentsTableOption(rowCount, onParticipantQueryClicked);
  } else if (status === CONSENT_STATUS_FILTER.allowed) {
    return acceptedConsentsTableOption(rowCount, onParticipantQueryClicked);
  } else if (status === CONSENT_STATUS_FILTER.rejected) {
    return rejectedConsentsTableOption(rowCount, onParticipantQueryClicked);
  } else if (status === CONSENT_STATUS_FILTER.pending) {
    return pendingConsentsTableOption(rowCount, onParticipantQueryClicked);
  } else if (status === CONSENT_STATUS_FILTER.revoked) {
    return revokedConsentsTableOption(rowCount, onParticipantQueryClicked);
  } else if (status === CONSENT_STATUS_FILTER.expired) {
    return expiredConsentsTableOption(rowCount, onParticipantQueryClicked);
  }
};
