import React from 'react';
import moment from 'moment';

export const getDataTableOptions = (totalCount, pageSize, onAction, t) => ({
  columns: [
    {
      dataField: 'name',
      text: t('Name'),
      sort: true,
    },
    {
      dataField: 'onboardingDate',
      text: t('Date of Onboarding'),
      sort: true,
      formatter: (cell, row) => {
        return <label>{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'tier',
      text: t('Tier'),
      sort: true,
    },
    {
      dataField: 'id',
      text: t('Actions'),
      formatter: (cell, row) => {
        return (
          <label className="table-action" onClick={() => onAction('consents', cell)}>
            <i className="fa fa-eye" />
            {t('Customers')}
          </label>
        );
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pageSize || 10,
    totalSize: totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
