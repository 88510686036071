import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';

export const DataTableOptions = (options, onEdit, onDelete, t) => ({
  columns: [
    {
      dataField: 'id',
      text: t('ID'),
      sort: true,
    },
    {
      dataField: 'email',
      text: t('Email'),
      sort: true,
    },
    {
      dataField: 'firstName',
      text: t('Name'),
      sort: true,
    },
    {
      dataField: 'surname',
      text: t('Surname'),
      sort: true,
    },
    {
      dataField: 'username',
      text: t('Username'),
      sort: true,
      formatter: cell => {
        const username = cell.split('#');
        return username.length === 2 ? username[1] : username[0];
      },
    },
    {
      dataField: 'createAt',
      text: t('Created'),
      sort: true,
      formatter: cell => {
        return <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'userRole.name',
      text: t('Role'),
      sort: true,
      formatter: (cell, row) => {
        return <label>{t(capitalize(cell))}</label>;
      },
    },

    {
      dataField: 'id',
      text: t('Actions'),
      formatter: (cell, row) => {
        return (
          <div className="d-flex gap-1">
            {options.currentUserId !== cell && (
              <>
                <button type="button" className="btn btn-danger btn-sm" onClick={() => onDelete(cell, row)}>
                  {t('Delete')}
                </button>
                <button type="button" className="btn btn-primary btn-sm" onClick={() => onEdit(cell, row)}>
                  {t('Edit')}
                </button>
              </>
            )}
          </div>
        );
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: 10,
    totalSize: options.totalDataSize || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
