import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { ENTITY_TYPE } from '../../../constants/domain';
import { cbjApi, jopaccApi } from '../../../../shared/api';
import DataTable from '../../../components/DataTable';

import { getDataTableOptions } from './datatable-options';

const onTableAction = (action, id, data, router, entityType) => {
  console.log(data);
  if (action === 'reporting') {
    router.push(`/${entityType === ENTITY_TYPE.JOPACC ? 'jopacc' : 'cbj'}/reporting/participant`, {
      participantId: id,
      totalConsumers: data.activeCustomers,
    });
  }
};

const JopaccReporting = props => {
  const user = useSelector(state => state.app.profile.data);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataTableOptions, setDataTableOptions] = useState(
    getDataTableOptions(0, 10, (action, id) => onTableAction(action, id, props.history, user.entityType), props.t)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response =
          user.entityType === ENTITY_TYPE.JOPACC
            ? await jopaccApi.getJopaccReportingParticipantsList()
            : await cbjApi.getCBJReportingParticipantsList();
        setData(response.data.data);
        setDataTableOptions(
          getDataTableOptions(
            response.data.data.length,
            10,
            (action, id, data) => onTableAction(action, id, data, props.history, user.entityType),
            props.t
          )
        );
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || 'Something went wrong');
      }
      setLoading(false);
    };

    fetchData();
  }, [props.history, user.entityType]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('Participants Reporting')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="d-flex mx-3 mb-3">
              <h4 className="font-size-24">{props.t('Participants Reporting')}</h4>
            </div>

            <DataTable data={data} options={dataTableOptions} />
          </>
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(JopaccReporting);
