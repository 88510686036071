import { capitalize } from 'lodash';
import React from 'react';

export const getDataTableOptions = (totalCount, pageSize, onAction, t) => ({
  columns: [
    {
      dataField: 'participant.name',
      text: t('Participant'),
      sort: false,
    },
    {
      dataField: 'tier',
      text: t('Tier'),
      sort: false,
    },
    {
      dataField: 'subscription',
      text: t('Subscription'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{t(capitalize(cell))}</label>;
      },
    },
    {
      dataField: 'totalBilling',
      text: t('Total Bill') + ' (' + t('JDs') + ')',
      sort: false,
      formatter: cell => {
        return (
          cell && (
            <label>
              {cell.toFixed(3)} {t('JDs')}
            </label>
          )
        );
      },
    },
    {
      dataField: 'participant.id',
      text: t('Actions'),
      formatter: (cell, row) => {
        return (
          <label
            className="table-action"
            onClick={() =>
              onAction('billing', {
                participantId: row.participant.id,
                participantName: row.participant.name,
              })
            }>
            <i className="fa fa-dollar-sign" />
            {t('Billing')}
          </label>
        );
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pageSize || 10,
    totalSize: totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
