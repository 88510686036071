import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { StyledCard } from './StyledCard';

const titles = {
  success: {
    label: 'Successful attempts',
    visible: true,
  },
  personal_info_error: {
    label: 'Reached the maximum errors',
    visible: true,
  },
  doc_error: {
    label: 'Document verification errors',
    visible: true,
  },
  face_error: {
    label: 'Face verification errors',
    visible: true,
  },
  address_error: {
    label: 'Address information errors',
    visible: true,
  },
  network_error: {
    label: 'Network connection errors',
    visible: true,
  },
  mismatch_error: {
    label: 'mismatch_error',
    visible: false,
  },
  unknown: {
    label: 'other',
    visible: false,
  },
};

const StatisticsCards = ({ t, data, handleClick, displayed }) => {
  return (
    <Row className="mb-4">
      {data.map(
        (report, key) =>
          titles[report.title].visible && (
            <Col lg="4" md="4" xl="2" key={'_col_' + key} className="mb-2">
              <StyledCard
                title={t(titles[report.title].label)}
                value={report.data?.all}
                handleClick={() => {
                  handleClick(key);
                }}
                selected={key === displayed}
              />
            </Col>
          )
      )}
    </Row>
  );
};

export default withTranslation()(StatisticsCards);
