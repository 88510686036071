import { capitalize } from 'lodash';
import React from 'react';

export const getDataTableOptions = (options, sizePerPage = 10, t) => ({
  columns: [
    {
      dataField: 'uid',
      text: t('No.'),
      sort: false,
    },
    {
      dataField: 'period',
      text: t('Period'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{t(capitalize(cell))}</label>;
      },
    },
    {
      dataField: 'amount',
      text: t('Amount'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{`${cell} ${t('JDs')}`}</label>;
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: sizePerPage || 10,
    totalSize: options?.totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
