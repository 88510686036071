import React from 'react';
import { Card, CardBody } from 'reactstrap';

export const StyledCard = ({ title, value, handleClick, selected }) => {
  return (
    <Card
      className="mini-stats-wid h-100"
      style={{ border: selected ? '1px solid #17a2b8' : '' }}
      onClick={handleClick}>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div className="flex-grow-1 ">
            <p className="text-muted fw-medium">{title}</p>
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="mb-0 ">{value}</h4>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
