import React from 'react';

export const getDataTableOptions = (totalCount, pageSize, onAction, t) => ({
  columns: [
    {
      dataField: 'participant',
      text: t('Participant'),
      sort: true,
    },
    {
      dataField: 'activeCustomers',
      text: t('Active Customers'),
      sort: true,
    },
    {
      dataField: 'id',
      text: t('Actions'),
      formatter: (cell, row) => {
        return (
          <label className="table-action" onClick={() => onAction('reporting', cell, row)}>
            <i className="fa fa-chart-pie" />
            {t('Reporting')}
          </label>
        );
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pageSize || 10,
    totalSize: totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
