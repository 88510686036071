import React, { useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, FormGroup, Row } from 'reactstrap';

import { api } from '../../../../shared/api';
import { updateEmail } from '../../../../shared/services/user';
import UiModal from '../../../components/Modal';
import extractErrorMessage from '../../../helpers/extractErrorMessage';

const VerifyEmailModal = ({ isOpen, onClose, newEmail, currentEmail, t, verifyOnly = false }) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      if (verifyOnly) {
        await api.verifyEmailOTP({
          email: currentEmail,
          isAuth: true,
          otp: code,
        });
      } else {
        await updateEmail({
          newEmail: newEmail,
          oldEmail: currentEmail,
          otp: code,
        });
      }

      toast.success(t('Email verified successfully'));
      onClose();
    } catch (error) {
      let message = extractErrorMessage(error) ?? t('error');
      setError(message);
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <UiModal
      isOpen={isOpen}
      toggle={onClose}
      body={
        <div className="p-2">
          <div className="text-center">
            <div className="avatar-md mx-auto">
              <div className="avatar-title rounded-circle bg-light">
                <i className="bx bxs-envelope h1 mb-0 text-primary" />
              </div>
            </div>
            <div className="p-2 mt-4">
              <h4>{t('Verify your email')}</h4>
              <p className="mb-5">
                {t('Please enter the 6 digit code sent to')} <span className="font-weight-semibold">{newEmail}</span>
              </p>
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col xs={12}>
                    <FormGroup className="verification">
                      <AuthCode
                        characters={6}
                        className="form-control form-control-lg text-center"
                        allowedCharacters="^[0-9]"
                        inputClassName="otp-input"
                        onChange={input => {
                          setError('');
                          setCode(input);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="invalid-label-container">{error && <label className="text-danger">{error}</label>}</div>

                <div className="mt-4">
                  <button disabled={loading || code.length !== 6} className="btn btn-success w-md" type="submit">
                    {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />} {t('Confirm')}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default withTranslation()(VerifyEmailModal);
