import React from 'react';

export const getDataTableOptions = (totalCount, pageSize, onAction, t) => ({
  columns: [
    {
      dataField: 'name',
      text: t('Tier Name'),
      sort: true,
    },
    {
      dataField: 'participantsCount',
      text: t('Participants Count'),
      sort: true,
    },
    {
      dataField: 'id',
      text: t('Actions'),
      formatter: (cell, row) => {
        return (
          <label
            className="table-action"
            onClick={() =>
              onAction('participants', {
                tierId: cell,
                tierName: row.name,
              })
            }>
            <i className="fa fa-eye" />
            {t('Participants')}
          </label>
        );
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pageSize || 10,
    totalSize: totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
