import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Form, FormFeedback, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { changePassword } from '../../../../shared/services/user';
import extractErrorMessage from '../../../helpers/extractErrorMessage';

const ChangePassword = ({ onClose = null, t }) => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('Old password is required')),
    password: Yup.string()
      .required(t('New password is required'))
      .min(8, t('Password must be at least 8 characters'))
      .max(100, t('Password must be at most 100 characters'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t('Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
      ),
    confirmPassword: Yup.string()
      .required(t('Confirm password is required'))
      .oneOf([Yup.ref('password')], t('Passwords must match')),
  });

  const onSubmit = async values => {
    try {
      setLoading(true);
      await changePassword(values);
      toast.success(t('Password Updated successfully'));
      setLoading(false);
      if (typeof onClose === 'function') {
        onClose();
      }
    } catch (error) {
      let message = extractErrorMessage(error) ?? t('error');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {formik => (
        <Form className="needs-formik" onSubmit={formik.handleSubmit}>
          <div>
            <div className="form-group mb-4">
              <label>{t('Old Password')}</label>
              <Input
                type="password"
                className="form-control"
                name="oldPassword"
                placeholder={t('Old Password')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword || ''}
                invalid={!!(formik.touched.oldPassword && formik.errors.oldPassword)}
              />
              {formik.touched.oldPassword && formik.errors.oldPassword ? (
                <FormFeedback type="invalid">{formik.errors.oldPassword}</FormFeedback>
              ) : null}
            </div>
            <div className="form-group mb-4">
              <label>{t('New Password')}</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                placeholder={t('New Password')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password || ''}
                invalid={!!(formik.touched.password && formik.errors.password)}
              />
              {formik.touched.password && formik.errors.password ? (
                <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
              ) : null}
            </div>
            <div className="form-group">
              <label>{t('Confirm Password')}</label>
              <Input
                type="password"
                className="form-control"
                name="confirmPassword"
                placeholder={t('Confirm Password')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword || ''}
                invalid={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <FormFeedback type="invalid">{formik.errors.confirmPassword}</FormFeedback>
              ) : null}
            </div>
          </div>

          <div className="modal-footer mt-4 pb-0">
            <Button
              disabled={loading}
              type="button"
              color="basic"
              onClick={() => {
                formik.resetForm();
                if (typeof onClose === 'function') {
                  onClose();
                }
              }}>
              {t('Cancel')}
            </Button>
            <Button disabled={loading} type="submit" color="primary">
              {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />} {t('Submit')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withTranslation()(ChangePassword);
