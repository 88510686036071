import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { get, map } from 'lodash';
import { withTranslation } from 'react-i18next';

import languages from '../../../common/languages';
import i18n from '../../../i18n';

const LanguageDropdown = ({ inline }) => {
  const [selectedLang, setSelectedLang] = useState('');
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const currentLanguage = localStorage.getItem('I18N_LANGUAGE') || 'en';
    document.documentElement.setAttribute('data-theme', currentLanguage);
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = lang => {
    i18n.changeLanguage(lang);
    localStorage.setItem('I18N_LANGUAGE', lang);
    document.documentElement.setAttribute('data-theme', lang);
    setSelectedLang(lang);
    window.location.reload();
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        {inline ? (
          <DropdownToggle className="btn" tag="button">
            {get(languages, `${selectedLang}.label`)}{' '}
            <img src={get(languages, `${selectedLang}.flag`)} alt="Skote" height="16" className="me-1" />
          </DropdownToggle>
        ) : (
          <DropdownToggle className="btn header-item" tag="button">
            <img src={get(languages, `${selectedLang}.flag`)} alt="Skote" height="16" className="me-1" />
          </DropdownToggle>
        )}
        <DropdownMenu className={`language-switch dropdown-menu-${selectedLang === 'ar' ? 'end' : 'start'}`}>
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? 'active' : 'none'}`}>
              <img src={get(languages, `${key}.flag`)} className="me-1" height="12" />
              <span className="align-middle">{get(languages, `${key}.label`)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
