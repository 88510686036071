import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';

import ChangePassword from './components/ChangePassword';

const JopaccUserSettings = ({ t }) => {
  return (
    <div className="container-fluid">
      <div className="d-flex mb-3">
        <h4 className="font-size-24">{t('Settings')}</h4>
      </div>
      <Card>
        <CardBody>
          <ChangePassword />
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(JopaccUserSettings);
