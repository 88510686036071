import React from 'react';
import { withTranslation } from 'react-i18next';

import UiModal from '../../../components/Modal';

import ChangePassword from './ChangePassword';

const ChangePasswordModal = ({ isOpen, onClose, t }) => {
  return (
    <UiModal
      isOpen={isOpen}
      title={t('Change Password')}
      toggle={onClose}
      body={<ChangePassword onClose={onClose} />}
    />
  );
};

export default withTranslation()(ChangePasswordModal);
