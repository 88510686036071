import React from 'react';
import { Redirect } from 'react-router-dom';

import Login from '../pages/Login';
import Logout from '../pages/Logout';
import { CustomerConsents, ParticipantConsents } from '../pages/Consents';
import ParticipantUsers from '../pages/UserManagement/ParticipantUsers';
import Settings from '../pages/Settings';
import BillingPage from '../pages/Billing';
import ReportingPage from '../pages/Reporting';
import Statistics from '../pages/Statistics';
import {
  JopaccParticipantConsents,
  JopaccUsers,
  UserConsentsHistory,
  Participants,
  JopaccConsents,
  Tiers,
} from '../pages/Jopacc';
import JopaccReporting from '../pages/Jopacc/JopaccReporting';
import TierParticipants from '../pages/Jopacc/TierParticipants';
import DetailsPage from '../pages/Consents/DetailsPage';
import JopaccBilling from '../pages/Jopacc/JopaccBilling';
import { ENTITY_TYPE } from '../constants/domain';
import CBJConsents from '../pages/Consents/CBJConsents/CBJConsents';
import CBJUsersPage from '../pages/UserManagement/CBJUsers';

const authProtectedRoutes = (role, type) => {
  const adminOnlyRoutes = [
    { path: '/billing', component: BillingPage },
    { path: '/reporting', component: ReportingPage },
    { path: '/jopacc/statistics', component: Statistics },

    { path: '/jopacc/roles', component: JopaccUsers },
    { path: '/jopacc/reporting', component: JopaccReporting },
    { path: '/jopacc/reporting/participant', component: ReportingPage },
    { path: '/jopacc/billing', component: JopaccBilling },
    { path: '/jopacc/billing/participant', component: BillingPage },

    { path: '/cbj/roles', component: CBJUsersPage },
    { path: '/cbj/participants', component: Participants },
    { path: '/cbj/participant-consents', component: JopaccParticipantConsents },
    { path: '/cbj/reporting', component: JopaccReporting },
    { path: '/cbj/reporting/participant', component: ReportingPage },
    { path: '/cbj/tiers', component: Tiers },
    { path: '/cbj/tiers/participants', component: TierParticipants },
  ];

  const managerRoutes = [
    { path: '/participant/customers', component: ParticipantConsents },
    { path: '/customers/details', component: DetailsPage },
    { path: '/jopacc/customers/history', component: UserConsentsHistory },
    { path: '/jopacc/customers', component: JopaccConsents },
    { path: '/participant/users', component: ParticipantUsers },
    { path: '/jopacc/tiers', component: Tiers },
    { path: '/jopacc/tiers/participants', component: TierParticipants },
    { path: '/jopacc/participants', component: Participants },
    { path: '/jopacc/participant-consents', component: JopaccParticipantConsents },

    { path: '/cbj/customers', component: CBJConsents },
  ];

  const anyRoutes = [
    { path: '/consents', component: CustomerConsents },
    { path: '/settings', component: Settings },
    { path: '/profile', component: Settings },
  ];

  const homePage =
    type === ENTITY_TYPE.JOPACC
      ? '/jopacc/customers'
      : type === ENTITY_TYPE.PARTICIPANT
      ? '/participant/customers'
      : type === ENTITY_TYPE.CBJ
      ? '/cbj/customers'
      : '/profile';

  return [
    ...(role === 'administrator' ? [...adminOnlyRoutes, ...managerRoutes, ...anyRoutes] : []),
    ...(role === 'manager' ? [...managerRoutes, ...anyRoutes] : []),
    ...(role === 'any' ? anyRoutes : []),
    { path: '/', exact: true, component: () => <Redirect to={homePage} /> },
  ];
};

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/jopacc/login', component: Login },
  { path: '/user/login', component: Login },
  { path: '/participant/login', component: Login },
  { path: '/cbj/login', component: Login },
  { path: '/logout', component: Logout },
];

export { authProtectedRoutes, publicRoutes };
