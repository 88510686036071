import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { connect, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import '../web/assets/scss/datepicker.scss';
import './assets/scss/theme.scss';

import HorizontalLayout from './components/HorizontalLayout';
import NonAuthLayout from './components/NonAuthLayout';
import VerticalLayout from './components/VerticalLayout';
import { authProtectedRoutes, publicRoutes } from './routes';
import Authmiddleware from './routes/route';
// default to 5 minutes
const timeout = process.env.REACT_APP_IDLE_SESSION_TIMEOUT_MS ?? 1000 * 60 * 5;

const IdlePromptModal = ({ isOpen, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <h4>{t("You've been idle for 5 minutes, you will auto logout in 15 seconds!")}</h4>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="danger"
          onClick={() => {
            window.location.href = '/logout';
          }}>
          {t('Logout')}
        </Button>
        <Button type="button" onClick={onConfirm}>
          {t('Continue Session')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const App = props => {
  const user = useSelector(state => state.app?.profile?.data);
  const [idlePromptOpen, setIdlePromptOpen] = useState(false);

  const handleOnActive = () => {
    console.log('active');
  };

  const handleOnIdle = () => {
    console.log('idle');
    if (window.location.pathname !== '/login') {
      window.location.href = '/logout';
    }
  };

  const handlePromptAnswer = () => {
    console.log('prompt');
    if (window.location.pathname !== '/login') {
      setIdlePromptOpen(true);
    }
  };

  const { reset, pause, resume } = useIdleTimer({
    timeout: +timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    promptTimeout: 15000,
    onPrompt: handlePromptAnswer,
  });

  useEffect(() => {
    if (window.location.pathname === '/login') {
      pause();
    } else {
      resume();
    }

    return () => {
      reset();
    };
  }, [window.location.pathname]);

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();

  const onIdlePromptConfirm = () => {
    reset();
    setIdlePromptOpen(false);
  };

  return (
    <React.Fragment>
      <IdlePromptModal isOpen={idlePromptOpen} onConfirm={onIdlePromptConfirm} />
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {authProtectedRoutes(user?.userRole?.name || 'any', user?.entityType).map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          <Route>
            <div className="not-found-container">
              <h2>The requested page not found</h2>
              <a href="/login" className="home-link">
                <span className="fa fa-home go-home-btn" />
              </a>
            </div>
          </Route>
        </Switch>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
