const packageName = 'jopacc';

export const setStorageItem = (key: string, value: string) => {
  try {
    localStorage.setItem(`${packageName}:${key}`, value);
  } catch (err) {
    return;
  }
};

export const getStorageItem = (key: string) => {
  try {
    const value = localStorage.getItem(`${packageName}:${key}`);

    if (value) {
      try {
        return JSON.parse(value);
      } catch (error) {
        return value;
      }
    }

    return '';
  } catch (err) {
    return '';
  }
};

export const removeStorageItem = (key: string) => {
  try {
    localStorage.removeItem(`${packageName}:${key}`);
  } catch (err) {
    return;
  }
};

export const clearSecureStorage = () => {
  try {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!!key && key !== 'i18nextLng' && key !== 'I18N_LANGUAGE') {
        localStorage.removeItem(key);
      }
    }
  } catch (err) {
    return;
  }
};
