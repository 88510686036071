const extractErrorMessage = (error: any) => {
  let message;

  if (typeof error?.response?.data?.message === 'string') {
    message = error?.response?.data?.message;
  } else if (typeof error?.response?.data?.message?.message === 'string') {
    message = error?.response?.data?.message?.message;
  } else if (typeof error?.response?.data?.error?.message === 'string') {
    message = error?.response?.data?.error?.message;
  } else {
    message = error?.response?.data?.error?.message?.[0];
  }

  return message;
};

export default extractErrorMessage;
