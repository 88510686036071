import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ENTITY_TYPE } from '../../constants/domain';

import ParticipantDashboard from './Participants/ParticipantDashboard';

const ReportingPage = ({ location, history }) => {
  const user = useSelector(state => state.app.profile);
  const type = user?.data?.entityType;
  let participantId = null;
  let totalConsumers = null;

  if (type === ENTITY_TYPE.JOPACC || type === ENTITY_TYPE.CBJ) {
    participantId = location?.state?.participantId;
    totalConsumers = location?.state?.totalConsumers;
  } else if (type === ENTITY_TYPE.PARTICIPANT) {
    participantId = user?.data?.entityId;
  }

  if (!participantId) {
    history.goBack();
  }

  return (
    <ParticipantDashboard
      participantId={participantId}
      isParticipant={type === ENTITY_TYPE.PARTICIPANT}
      totalConsumers={totalConsumers}
    />
  );
};

export default withRouter(ReportingPage);
