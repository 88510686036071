export const getAsyncItem = (key: string) => {
  try {
    const value = localStorage.getItem(key);

    if (value) {
      try {
        return JSON.parse(value);
      } catch (error) {
        return value;
      }
    }

    return '';
  } catch (err) {
    return '';
  }
};
