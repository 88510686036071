import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';

const SearchBar = ({ t, loading, onApply }) => {
  const [id, setId] = useState('');
  const [username, setUsername] = useState('');
  const [nationality, setNationality] = useState('');
  const [dateRange, setDateRange] = useState({
    dateOfOnboarding: [null, null],
  });

  const onSubmit = () => {
    onApply({
      dateOfOnboardingFrom: dateRange.dateOfOnboarding[0]
        ? moment(dateRange.dateOfOnboarding[0]).format('YYYY-MM-DD')
        : null,
      dateOfOnboardingTo: dateRange.dateOfOnboarding[1]
        ? moment(dateRange.dateOfOnboarding[1]).format('YYYY-MM-DD')
        : null,
      username,
      nationality,
      id,
    });
  };

  return (
    <Row className="mb-4">
      <Col md="2">
        <label>{t('ID')}</label>
        <Input value={id} onChange={e => setId(e.target.value)} />
      </Col>
      {/* <Col md="2">
        <label>{t('Username')}</label>
        <Input value={username} onChange={e => setUsername(e.target.value)} />
      </Col> */}
      <Col md="2">
        <label>{t('Nationality')}</label>
        <Input value={nationality} onChange={e => setNationality(e.target.value)} />
      </Col>
      <Col md="4">
        <label>{t('Date of Onboarding')}</label>
        <DatePicker
          selectsRange={true}
          startDate={dateRange.dateOfOnboarding[0]}
          endDate={dateRange.dateOfOnboarding[1]}
          onChange={update => {
            setDateRange({
              ...dateRange,
              dateOfOnboarding: update,
            });
          }}
          isClearable={true}
          clearButtonClassName="datepicker-clear-button"
          wrapperClassName="datepicker-wrapper"
        />
      </Col>

      <Col md="2" className="d-flex apply-btn-container">
        <Button disabled={loading} onClick={onSubmit} type="button" className="flex-fill" color="primary">
          {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />} {t('Apply')}
        </Button>
      </Col>
    </Row>
  );
};

export default withTranslation()(SearchBar);
