import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { toast } from 'react-toastify';
import { Col, Container, Row, Spinner } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import qs from 'qs';
import moment from 'moment';

import { ENTITY_TYPE } from '../../../constants/domain';
import { jopaccApi, participantAPI, reportingApi } from '../../../../shared/api';

import GeneralCards from './GeneralCards';
import StatisticsCards from './StatisticsCards';
import OnboardingStatisticsCards from './OnboardingStaticsCard';

const ParticipantDashboard = props => {
  const user = useSelector(state => state.app.profile.data);
  const [loading, setLoading] = useState(false);
  const [totalBilling, setTotalBilling] = useState({
    totalBilling: '...',
    tier: 'loading...',
  });
  const [statistics, setStatistics] = useState({
    all: 0,
    pending: 0,
    allowed: 0,
    rejected: 0,
    revoked: 0,
  });
  const [OnboardingStatistics, setOnboardingStatistics] = useState();
  const [subscriptionData, setSubscriptionData] = useState({
    joined: 'Loading...',
    billingType: '...',
    fee: '...',
    totalCustomers: 0,
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [OnboardingDateRange, setOnboardingDateRange] = useState([null, null]);

  useEffect(() => {
    setLoading(true);
    if (!props.participantId) {
      return;
    }

    const fetchData = async () => {
      try {
        const isCBJ = user.entityType === ENTITY_TYPE.CBJ;
        const totalBillingResponse = !isCBJ ? await reportingApi.getTotalBillingAndTier(props.participantId) : null;
        const subscriptionResponse = !isCBJ
          ? await reportingApi.getSubscription(props.participantId)
          : await reportingApi.getParticipantSubscription(props.participantId);

        if (isCBJ) {
          const joined = subscriptionResponse?.data.data.createAt;
          const tier = subscriptionResponse?.data.data.tier.name;

          setTotalBilling({
            totalBilling: 0,
            tier,
          });

          setSubscriptionData(prev => ({
            ...prev,
            joined: joined,
            billingType: 'No API',
            fee: 'No API',
            totalCustomers: props.totalConsumers,
          }));
        } else {
          const { totalBilling, tier } = totalBillingResponse?.data.data ?? {
            totalBilling: 0,
            tier: 'No API',
          };
          setTotalBilling({
            totalBilling: `${totalBilling} ${props.t('JDs')}`,
            tier,
          });
          setSubscriptionData(prev => ({
            ...prev,
            ...subscriptionResponse?.data.data,
            totalCustomers: props.totalConsumers,
          }));
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || props.t('error'));
      }
    };

    fetchData();
    setLoading(false);
  }, [props.participantId, user.entityType]);

  useEffect(() => {
    if ((dateRange[0] && dateRange[1]) || (!dateRange[0] && !dateRange[1])) {
      fetchStats();
    }
  }, [dateRange]);

  const fetchStats = async () => {
    const queryObj = {
      id: props.participantId,
      ...(dateRange[0]
        ? {
            dateOfIssueFrom: moment(dateRange[0]).format('YYYY-MM-DD'),
            dateOfIssueTo: moment(dateRange[1]).format('YYYY-MM-DD'),
          }
        : {}),
    };
    const statisticsResponse = await reportingApi.getStatistics(qs.stringify(queryObj));
    setStatistics(statisticsResponse.data.data);
  };

  useEffect(() => {
    if ((OnboardingDateRange[0] && OnboardingDateRange[1]) || (!OnboardingDateRange[0] && !OnboardingDateRange[1])) {
      fetchOnboardingStats();
    }
  }, [OnboardingDateRange]);

  const fetchOnboardingStats = async () => {
    const queryObj = {
      ...(OnboardingDateRange[0]
        ? {
            dateFrom: moment(OnboardingDateRange[0]).format('YYYY-MM-DD'),
            dateTo: moment(OnboardingDateRange[1]).format('YYYY-MM-DD'),
          }
        : {}),
    };
    const statisticsResponse = props.isParticipant
      ? await participantAPI.getAllOnboardingStatistics(qs.stringify(queryObj))
      : await jopaccApi.getParticipantsOnboardingStatistics(props.participantId, qs.stringify(queryObj));
    setOnboardingStatistics(statisticsResponse.data.data);
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t('Reporting')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="d-flex mb-3">
              <h4 className="font-size-24">{props.t('General')}</h4>
            </div>
            <Row>
              <Col xl="12">
                <GeneralCards billing={totalBilling} subscription={subscriptionData} />
              </Col>
            </Row>
            <Row>
              <div className="d-flex mb-3 mt-4 justify-content-between align-items-end">
                <h4 className="font-size-24">{props.t('Statistics')}</h4>
                <Col md="4" xl="3">
                  <label>{props.t('Consents Period')}</label>
                  <DatePicker
                    selectsRange={true}
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    onChange={setDateRange}
                    isClearable={true}
                    clearButtonClassName="datepicker-clear-button"
                    wrapperClassName="datepicker-wrapper"
                  />
                </Col>
              </div>
            </Row>
            <Row>
              <Col xl="12">
                <StatisticsCards data={statistics} />
              </Col>
            </Row>

            <Row>
              <div className="d-flex mb-3 mt-4 justify-content-between align-items-end">
                <h4 className="font-size-24">{props.t('Onboarding Statistics')}</h4>
                <Col md="4" xl="3">
                  <label>{props.t('Onboarding Period')}</label>
                  <DatePicker
                    selectsRange={true}
                    startDate={OnboardingDateRange[0]}
                    endDate={OnboardingDateRange[1]}
                    onChange={setOnboardingDateRange}
                    isClearable={true}
                    clearButtonClassName="datepicker-clear-button"
                    wrapperClassName="datepicker-wrapper"
                  />
                </Col>
              </div>
            </Row>
            <Row>
              <Col xl="12">{OnboardingStatistics && <OnboardingStatisticsCards data={OnboardingStatistics} />}</Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(ParticipantDashboard);
