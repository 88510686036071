export const COLORS = {
  white: '#FFFFFF',
  yellow: '#FAB500',
  yellow100: '#FFFCF4',
  darkGray: '#464646',
  gray: '#9E9E9E',
  gray2: '#CECECE',
  gray500: '#6b6b6b',
  lightGray: '#E5E5E5',
  lightGrayOpacity: 'rgba(229, 229, 229, 0.25)',
  gray100: '#EBEBEB',
  gray50: '#F8F8F8', // TODO: replace all [lighterGray] with [gray50]
  lighterGray: '#F8F8F8',
  green400: '#27AA64',
  green300: '#27D964',
  green200: '#CCFFDE',
  green100: '#E5FFEE',
  red300: '#EB5757',
  red200: '#FFDCDC',
  red100: '#FBE6E6',
  black: '#333333',
  transparent: '#FFFFFF00',
  shadow: '#00000070',
  lightShadow: '#00000020',
  themeGreen: '#50AA5A',
  themeGreenOpacity: 'rgba(80, 170, 90, 0.25)',
  turquoise: '#30b2c1',
  darkBlue: '#004876',
  crimson: "#DC143C"
};
