import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import DataTable from '../../../components/DataTable';
import { useGetConsents } from '../../../../shared/hooks/useGetConsents';
import { CONSENT_STATUS_FILTER } from '../../../../shared/constants/enums';
import { COLORS } from '../../../../shared/constants/colors';

import { DataTableOptions } from './constants';
import { api } from '../../../../shared/api';
import { toast } from 'react-toastify';

const Consents = ({ t }) => {
  const [participantModalOpen, setParticipantModalOpen] = useState(false);
  const [participantData, setParticipantData] = useState({});
  const [participantDataLoading, setParticipantDataLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [filteredConsents, setFilteredConsents] = useState();
  const [filter, setFilter] = useState(CONSENT_STATUS_FILTER.all);
  const [consentCounts, setConsentCounts] = useState({
    all: 0,
    pending: 0,
    allowed: 0,
    revoked: 0,
    rejected: 0,
    expired: 0,
  });
  const { consents } = useSelector(state => state.app);

  const onParticipantQueryClicked = async participantId => {
    setParticipantData({});
    setParticipantModalOpen(true);
    setParticipantDataLoading(true);

    try {
      const response = await api.getParticipantTierInfo(participantId);
      const resData = response.data.data;
      let humanized = resData.permissions.tierPermissions
        .split(',')
        .map(word => _.capitalize(_.trim(_.snakeCase(word).replace(/_id$/, '').replace(/_/g, ' '))))
        .join(', ');
      resData.permissions.tierPermissions = humanized;
      setParticipantData(resData);
    } catch (error) {
      setParticipantData({
        name: t('error'),
      });
      toast.error(t('error'));
    }

    setParticipantDataLoading(false);
  };

  const [dataTableOptions, setDataTableOptions] = useState(
    DataTableOptions(CONSENT_STATUS_FILTER.all, 0, onParticipantQueryClicked, t)
  );

  const onError = useCallback(error => console.log(error), []);

  useGetConsents(onError);

  useEffect(() => {
    if (consents?.attributes) {
      setLoading(false);
      setFilteredConsents(consents?.attributes?.consents ?? []);
      if (consents?.attributes) {
        setConsentCounts(consents.attributes['consents-counts']);
      }
    }
  }, [consents]);

  useEffect(() => {
    switch (filter) {
      case CONSENT_STATUS_FILTER.allowed:
        return setFilteredConsents(
          consents?.attributes?.consents.filter(consent => consent.statusKey === 'accepted') ?? []
        );
      case CONSENT_STATUS_FILTER.pending:
        return setFilteredConsents(
          consents?.attributes?.consents.filter(consent => consent.statusKey === 'pending') ?? []
        );
      case CONSENT_STATUS_FILTER.revoked:
        return setFilteredConsents(
          consents?.attributes?.consents.filter(consent => consent.statusKey === 'revoked') ?? []
        );
      case CONSENT_STATUS_FILTER.rejected:
        return setFilteredConsents(
          consents?.attributes?.consents.filter(consent => consent.statusKey === 'rejected') ?? []
        );
      case CONSENT_STATUS_FILTER.expired:
        return setFilteredConsents(
          consents?.attributes?.consents.filter(consent => consent.statusKey === 'expired') ?? []
        );
      default:
        return setFilteredConsents(consents?.attributes?.consents ?? []);
    }
  }, [filter, consents]);

  useEffect(() => {
    setDataTableOptions(DataTableOptions(filter, filteredConsents?.length ?? 0, onParticipantQueryClicked, t));
  }, [filter, filteredConsents]);

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={participantModalOpen} toggle={() => setParticipantModalOpen(pre => !pre)}>
        <ModalHeader toggle={() => setParticipantModalOpen(pre => !pre)}>{t('Participant Tier')}</ModalHeader>
        <ModalBody>
          {participantDataLoading ? (
            <Spinner />
          ) : (
            <div>
              <h3>{participantData.name}</h3>
              <h5>{t('Have access to')}</h5>
              <p>{participantData.permissions?.tierPermissions}</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setParticipantModalOpen(pre => !pre)}>
            {t('Close')}
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <MetaTags>
          <title>{t('Customers')} | Jopacc</title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div className="loading">
              <Spinner />
            </div>
          ) : (
            <div>
              <div className="ms-3">
                <h4 className="font-size-24 mb-2">{t('My Consents')}</h4>
              </div>
              <div className="d-flex">
                <button
                  className={'btn btn-sm px-3 font-size-14 header-item'}
                  style={filter === CONSENT_STATUS_FILTER.all ? { color: COLORS.yellow } : {}}
                  type="button"
                  onClick={() => setFilter(CONSENT_STATUS_FILTER.all)}>
                  {`${t('All Consents')} ${consentCounts.all}`}
                </button>
                <button
                  className={'btn btn-sm px-3 font-size-14 header-item'}
                  style={filter === CONSENT_STATUS_FILTER.pending ? { color: COLORS.yellow } : {}}
                  type="button"
                  onClick={() => setFilter(CONSENT_STATUS_FILTER.pending)}>
                  {`${t('Pending')} ${consentCounts.pending}`}
                </button>
                <button
                  className={'btn btn-sm px-3 font-size-14 header-item'}
                  style={filter === CONSENT_STATUS_FILTER.allowed ? { color: COLORS.yellow } : {}}
                  type="button"
                  onClick={() => setFilter(CONSENT_STATUS_FILTER.allowed)}>
                  {`${t('Approved')} ${consentCounts.allowed}`}
                </button>
                <button
                  className={'btn btn-sm px-3 font-size-14 header-item'}
                  style={filter === CONSENT_STATUS_FILTER.rejected ? { color: COLORS.yellow } : {}}
                  type="button"
                  onClick={() => setFilter(CONSENT_STATUS_FILTER.rejected)}>
                  {`${t('Rejected')} ${consentCounts.rejected}`}
                </button>
                <button
                  className={'btn btn-sm px-3 font-size-14 header-item'}
                  style={filter === CONSENT_STATUS_FILTER.revoked ? { color: COLORS.yellow } : {}}
                  type="button"
                  onClick={() => setFilter(CONSENT_STATUS_FILTER.revoked)}>
                  {`${t('Revoked')} ${consentCounts.revoked}`}
                </button>
                <button
                  className={'btn btn-sm px-3 font-size-14 header-item'}
                  style={filter === CONSENT_STATUS_FILTER.expired ? { color: COLORS.yellow } : {}}
                  type="button"
                  onClick={() => setFilter(CONSENT_STATUS_FILTER.expired)}>
                  {`${t('Expired')} ${consentCounts.expired}`}
                </button>
              </div>
              <DataTable data={filteredConsents} options={dataTableOptions} />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

Consents.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Consents);
