import React, { useState } from 'react';
import Select from 'react-select';
import { Row, Form, Input, FormFeedback, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import UiModal from '../../../components/Modal';
import { addUser } from '../../../../shared/services/userManagement/participantUsers';
import { addJopaccUser } from '../../../../shared/services/userManagement/jopaccUsers';
import { addCBJUser } from '../../../../shared/services/userManagement/cbjUsers';
import { ENTITY_TYPE } from '../../../constants/domain';

import { localizedUserRoles } from './constants';
import extractErrorMessage from "../../../helpers/extractErrorMessage";

const AddUserModal = ({ isOpen, toggle, onAddedCallback = undefined, onErrorCallback = undefined, t }) => {
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(state => state.app.profile.data);
  const [userRoleOptions, setUserRoleOptions] = useState(localizedUserRoles(t)[currentUser.entityType]);

  const initialValues = {
    // idNumber: '',
    firstName: '',
    lastName: '',
    surname: '',
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    userRoleId: '',
  };

  const validationSchema = Yup.object().shape({
    // idNumber: Yup.number().required('Please enter your national National ID'),
    firstName: Yup.string()
      .matches(/^[A-Za-z]([A-Za-z.\s-]*[A-Za-z])*$/, t('This field can only contain letters, hyphen and period'))
      .max(100, t('First name must be at most 100 characters long'))
      .required(t('This value is required')),
    // lastName: Yup.string().required('This value is required'),
    surname: Yup.string()
      .matches(/^[A-Za-z]([A-Za-z.\s-]*[A-Za-z])*$/, t('This field can only contain letters, hyphen and period'))
      .max(100, t('Surname must be at most 100 characters long'))
      .required(t('This value is required')),
    username: Yup.string()
      .required(t('This value is required'))
      .min(8, t('Username must be at least 8 characters long'))
      .max(30, t('Username must be at most 30 characters long'))
      .matches(/^[A-Za-z]([A-Za-z0-9.]*[A-Za-z0-9])*$/, t('Username can only contain letter, numbers and period')),
    password: Yup.string()
      .required(t('Password is required'))
      .min(8, t('Password must be at least 8 characters'))
      .max(100, t('Password must be at most 100 characters'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t('Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
      ),
    confirmPassword: Yup.string()
      .required(t('Confirm password is required'))
      .oneOf([Yup.ref('password')], t('Passwords must match')),
    email: Yup.string().email(t('Must be a valid Email')).max(255).required(t('Email is required')),
    userRoleId: Yup.string().required(t('Please select a role')),
  });

  const onSubmit = async values => {
    try {
      setLoading(true);

      values.lastName = values.surname;
      values.username = values.username?.toLowerCase().trim();

      if (currentUser.entityType === ENTITY_TYPE.JOPACC) {
        await addJopaccUser(values);
      } else if (currentUser.entityType === ENTITY_TYPE.PARTICIPANT) {
        await addUser(values);
      } else if (currentUser.entityType === ENTITY_TYPE.CBJ) {
        await addCBJUser(values);
      } else {
        if (typeof onErrorCallback === 'function') {
          onErrorCallback(t("Couldn't validate current user"));
        }
        toast.error(t("Couldn't validate current user"));
        return;
      }

      toast.success(t('User added successfully'));
      setLoading(false);
      if (typeof onAddedCallback === 'function') {
        onAddedCallback(values);
      }
    } catch (error) {
      if (typeof onErrorCallback === 'function') {
        onErrorCallback(error);
      }

      let message = extractErrorMessage(error) ?? t('error');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <UiModal
      isOpen={isOpen}
      toggle={toggle}
      title={t('Add User')}
      body={
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {formik => (
            <Form className="needs-formik" onSubmit={formik.handleSubmit}>
              {/* <Row className="mb-3">
                <label className="col-md-4 col-form-label">National ID</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('National ID')}
                    name="idNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.idNumber || ''}
                    invalid={!!(formik.touched.idNumber && formik.errors.idNumber)}
                  />
                  {formik.touched.idNumber && formik.errors.idNumber ? (
                    <FormFeedback type="invalid">{formik.errors.idNumber}</FormFeedback>
                  ) : null}
                </div>
              </Row> */}
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('First Name')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('First Name')}
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName || ''}
                    invalid={!!(formik.touched.firstName && formik.errors.firstName)}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <FormFeedback type="invalid">{formik.errors.firstName}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              {/* <Row className="mb-3">
                <label className="col-md-4 col-form-label">Last Name</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('Last Name')}
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName || ''}
                    invalid={!!(formik.touched.lastName && formik.errors.lastName)}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <FormFeedback type="invalid">{formik.errors.lastName}</FormFeedback>
                  ) : null}
                </div>
              </Row> */}
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Surname')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('Surname')}
                    name="surname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.surname || ''}
                    invalid={!!(formik.touched.surname && formik.errors.surname)}
                  />
                  {formik.touched.surname && formik.errors.surname ? (
                    <FormFeedback type="invalid">{formik.errors.surname}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Email')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="email"
                    placeholder={t('Email')}
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email || ''}
                    invalid={!!(formik.touched.email && formik.errors.email)}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Username')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder={t('Username')}
                    name="username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username || ''}
                    invalid={!!(formik.touched.username && formik.errors.username)}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <FormFeedback type="invalid">{formik.errors.username}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Password')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password || ''}
                    invalid={!!(formik.touched.password && formik.errors.password)}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label">{t('Confirm Password')}</label>
                <div className="col-md-8">
                  <Input
                    className="form-control"
                    type="password"
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword || ''}
                    invalid={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <FormFeedback type="invalid">{formik.errors.confirmPassword}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <Row>
                <label className="col-md-4 col-form-label">{t('Role')}</label>
                <div className="col-md-8">
                  <Select
                    name="userRoleId"
                    placeholder={t('Select Role')}
                    options={userRoleOptions}
                    className={formik.errors.userRoleId ? 'is-invalid' : ''}
                    value={userRoleOptions?.find(option => option.value === formik.values.userRoleId)}
                    onChange={option => formik.setFieldValue('userRoleId', option.value)}
                  />
                  {formik.touched.userRoleId && formik.errors.userRoleId ? (
                    <FormFeedback type="invalid">{formik.errors.userRoleId}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              <div className="modal-footer mt-4 pb-0">
                <Button disabled={loading} type="button" color="basic" onClick={toggle}>
                  {t('Cancel')}
                </Button>
                <Button disabled={loading} type="submit" color="primary">
                  {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />} {t('Submit')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default withTranslation()(AddUserModal);
