import React from 'react';
import moment from 'moment';

import { ENTITY_TYPE } from '../../../constants/domain';

export const getDataTableOptions = (totalCount, pageSize, onAction, entityType, userRole, t) => ({
  columns: [
    {
      dataField: 'name',
      text: t('Name'),
      sort: true,
    },
    {
      dataField: 'onboardingDate',
      text: t('Date of Onboarding'),
      sort: true,
      formatter: (cell, row) => {
        return <label>{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'id',
      text: t('Actions'),
      formatter: (cell, row) => {
        return (
          <>
            <label className="table-action" onClick={() => onAction('consents', cell)}>
              <i className="fa fa-eye" />
              {t('Consents')}
            </label>
            {userRole !== 'manager' && (
              <label className="table-action ml-4" onClick={() => onAction('reporting', cell)}>
                <i className="fa fa-chart-pie" />
                {t('Reporting')}
              </label>
            )}
            {entityType !== ENTITY_TYPE.CBJ && userRole !== 'manager' && (
              <label
                className="table-action ml-4"
                onClick={() =>
                  onAction('billing', {
                    participantId: cell,
                    participantName: row.name,
                  })
                }>
                <i className="fa fa-dollar-sign" />
                {t('Billing')}
              </label>
            )}
          </>
        );
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'id',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pageSize || 10,
    totalSize: totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
