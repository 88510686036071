import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { Button, Container, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import DataTable from '../../../components/DataTable';
import UiModal from '../../../components/Modal';
import { getCBJUsers, deleteUser } from '../../../../shared/services/userManagement/cbjUsers';
import AddUserModal from '../components/AddUserModal';
import EditUserModal from '../components/EditUserModal';

import { DataTableOptions } from './constants';

const CBJUsersPage = props => {
  const user = useSelector(state => state.app.profile.data);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [tempSelectedUser, setTempSelectedUser] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataTableOptions, setDataTableOptions] = useState(
    DataTableOptions({ totalDataSize: 0, currentUserId: user.id }, null, null, props.t)
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await getCBJUsers();
      setData(response);
    } catch (error) {
      toast.error(error?.response?.data?.message || props.t('error'));
    }

    setLoading(false);
  };

  useEffect(() => {
    setDataTableOptions(
      DataTableOptions(
        { totalDataSize: data.length ?? 0, currentUserId: user.id },
        onEditRequested,
        onDeleteRequested,
        props.t
      )
    );
  }, [data, user.id]);

  const resetDeleteState = () => {
    setDeleteModalOpen(false);
    setTempSelectedUser(null);
  };

  const onDeleteRequested = (id, row) => {
    setTempSelectedUser(row);
    setDeleteModalOpen(true);
  };

  const onDeleteConfirmed = async () => {
    setSubmitting(true);
    try {
      await deleteUser(tempSelectedUser.id);
      await fetchUsers();
      toast.success(props.t('User Deleted Successfully'));
      resetDeleteState();
    } catch (error) {
      toast.error(error?.message ?? props.t('error'));
      console.error(error);
    }

    setSubmitting(false);
  };

  const onEditRequested = (id, row) => {
    setTempSelectedUser(row);
    setEditModalOpen(true);
  };

  const resetModalStates = (withReload = false) => {
    return async () => {
      setAddModalOpen(false);
      setEditModalOpen(false);
      setTempSelectedUser(null);

      if (withReload) {
        await fetchUsers();
      }
    };
  };

  return (
    <div className="page-content">
      <AddUserModal isOpen={addModalOpen} onAddedCallback={resetModalStates(true)} toggle={resetModalStates()} />

      <EditUserModal
        user={tempSelectedUser}
        isOpen={editModalOpen && tempSelectedUser}
        onEditCallback={resetModalStates(true)}
        toggle={resetModalStates()}
      />

      <UiModal
        isOpen={deleteModalOpen}
        toggle={resetDeleteState}
        title={props.t('Delete User')}
        body={props.t('Are you sure you want to delete this user?')}
        footer={
          <>
            <Button disabled={submitting} type="button" color="basic" onClick={resetDeleteState}>
              {props.t('Cancel')}
            </Button>
            <Button disabled={submitting} type="button" color="danger" onClick={onDeleteConfirmed}>
              {submitting && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />} {props.t('Yes')}
            </Button>
          </>
        }
      />

      <MetaTags>
        <title>{props.t('Roles')} | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between mx-3 mb-3">
              <h4 className="font-size-24">{props.t('Roles')}</h4>
              <button type="button" className="btn btn-primary" onClick={() => setAddModalOpen(true)}>
                {props.t('Add New User')}
              </button>
            </div>

            <DataTable data={data} options={dataTableOptions} />
          </>
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(CBJUsersPage);
