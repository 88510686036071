import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { toast } from 'react-toastify';
import { Container, Spinner } from 'reactstrap';

import { getUserProfile } from '../../../shared/services/user';
import { ENTITY_TYPE } from '../../constants/domain';
import './settings.scss';

import CBJUserSettings from './CBJUserSettings';
import CustomerUserSettings from './CustomerUserSettings';
import JopaccUserSettings from './JopaccUserSettings';
import ParticipantUserSettings from './ParticipantUserSettings';

const Settings = ({ t: translate }) => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const response = await getUserProfile();
      setProfile(response.data);
    } catch (e) {
      toast.error(e?.response?.data?.message || 'Something went wrong');
    }

    setLoading(false);
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Settings | Jopacc</title>
      </MetaTags>
      <Container fluid>
        {loading ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <>
            {/* TODO: Send the profile to <CustomerUserSettings /> and remove duplicate code */}
            {profile?.entityType === ENTITY_TYPE.USER && <CustomerUserSettings />}
            {profile?.entityType === ENTITY_TYPE.JOPACC && <JopaccUserSettings />}
            {profile?.entityType === ENTITY_TYPE.CBJ && <CBJUserSettings />}
            {profile?.entityType === ENTITY_TYPE.PARTICIPANT && <ParticipantUserSettings />}
          </>
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(Settings);
