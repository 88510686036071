import { combineReducers } from 'redux';

import app from '../../shared/store/reducers/app.reducer';

import Layout from './layout/reducer';
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';
import ecommerce from './e-commerce/reducer';
import calendar from './calendar/reducer';
import chat from './chat/reducer';
import crypto from './crypto/reducer';
import invoices from './invoices/reducer';
import projects from './projects/reducer';
import tasks from './tasks/reducer';
import contacts from './contacts/reducer';
import mails from './mails/reducer';
import Dashboard from './dashboard/reducer';
import DashboardSaas from './dashboard-saas/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  app,
});

export default rootReducer;
