import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const BillingSearchBar = ({ t, loading, onApply }) => {
  const [status, setStatus] = useState({ value: '1', label: t('All') });
  const [dateRange, setDateRange] = useState({
    dateOfIssue: [null, null],
    dateOfOperation: [null, null],
  });

  const onSubmit = () => {
    onApply({
      dateOfOperationFrom: dateRange.dateOfOperation[0]
        ? moment(dateRange.dateOfOperation[0]).format('YYYY-MM-DD')
        : null,
      dateOfOperationTo: dateRange.dateOfOperation[1]
        ? moment(dateRange.dateOfOperation[1]).format('YYYY-MM-DD')
        : null,
      dateOfIssueFrom: dateRange.dateOfIssue[0] ? moment(dateRange.dateOfIssue[0]).format('YYYY-MM-DD') : null,
      dateOfIssueTo: dateRange.dateOfIssue[1] ? moment(dateRange.dateOfIssue[1]).format('YYYY-MM-DD') : null,
      status: status.value === '1' ? null : status.value,
    });
  };

  return (
    <Row className="mb-4">
      <Col md="2">
        <label>{t('Status')}</label>
        <Select
          name="status"
          placeholder={t('Select Status')}
          options={[
            { value: '', label: t('All') },
            { value: 'accepted', label: t('Approved') },
            { value: 'revoked', label: t('Revoked') },
          ]}
          value={status}
          onChange={option => setStatus(option)}
        />
      </Col>
      <Col md="4">
        <label>{t('Date of Issue')}</label>
        <DatePicker
          selectsRange={true}
          startDate={dateRange.dateOfIssue[0]}
          endDate={dateRange.dateOfIssue[1]}
          onChange={update => {
            setDateRange({
              ...dateRange,
              dateOfIssue: update,
            });
          }}
          isClearable={true}
          clearButtonClassName="datepicker-clear-button"
          wrapperClassName="datepicker-wrapper"
        />
      </Col>
      <Col md="4">
        <label>{t('Date of Last Operation')}</label>
        <DatePicker
          selectsRange={true}
          startDate={dateRange.dateOfOperation[0]}
          endDate={dateRange.dateOfOperation[1]}
          onChange={update => {
            setDateRange({
              ...dateRange,
              dateOfOperation: update,
            });
          }}
          isClearable={true}
          clearButtonClassName="datepicker-clear-button"
          wrapperClassName="datepicker-wrapper"
        />
      </Col>
      <Col md="2" className="d-flex apply-btn-container">
        <Button disabled={loading} onClick={onSubmit} type="button" className="flex-fill" color="primary">
          {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />} {t('Apply')}
        </Button>
      </Col>
    </Row>
  );
};

export default withTranslation()(BillingSearchBar);
