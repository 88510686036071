import React from 'react';
import moment from 'moment';
import { capitalize } from 'lodash';

export const getDataTableOptions = (options, pageSize, t) => ({
  columns: [
    {
      dataField: 'uid',
      text: t('No.'),
      sort: false,
    },
    {
      dataField: 'consentId',
      text: t('Consent ID'),
      sort: false,
      formatter: cell => {
        return <label>{cell ?? '-'}</label>;
      },
    },
    {
      dataField: 'firstName',
      text: t('First Name'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{capitalize(cell)}</label>;
      },
    },
    {
      dataField: 'surname',
      text: t('Surname'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{capitalize(cell)}</label>;
      },
    },
    {
      dataField: 'idNumber',
      text: t('National ID'),
      sort: false,
    },
    {
      dataField: 'status',
      text: t('Status'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{t(capitalize(cell))}</label>;
      },
    },
    {
      dataField: 'dateOfIssue',
      text: t('Date of Issue'),
      sort: false,
      formatter: (cell, row) => {
        return <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label>;
      },
    },
    {
      dataField: 'dateOfOperation',
      text: t('Date of Last Operation'),
      sort: false,
      formatter: (cell, row) => {
        return cell ? <label className="ltr">{moment(cell).format('DD.MM.YYYY, hh:mm A')}</label> : '';
      },
    },
    {
      dataField: 'amount',
      text: t('Amount'),
      sort: false,
      formatter: (cell, row) => {
        return <label>{`${cell} ${t('JDs')}`}</label>;
      },
    },
  ],
  defaultSorted: [
    {
      dataField: 'invoiceNo',
      order: 'asc',
    },
  ],
  pageOptions: {
    sizePerPage: pageSize || 10,
    totalSize: options?.totalCount || 0,
    custom: false,
  },
  selectRow: {
    mode: 'checkbox',
    hideSelectColumn: true,
  },
});
